import { ReactComponent as Error500 } from 'assets/icons/e500.svg';
import { ReactComponent as Envelope } from 'assets/icons/envelope.svg';
import { ReactComponent as HidePassIcon } from 'assets/icons/hidden-password.svg';
import { ReactComponent as Photo } from 'assets/icons/photo.svg';
import { ReactComponent as ShowPassIcon } from 'assets/icons/shown-password.svg';
import { ReactComponent as TelegramLogo } from 'assets/icons/telegram-logo.svg';
import { IconName } from 'common/types/types';
import { getValidClasses } from 'helpers/helpers';
import { FC, SVGProps } from 'react';

import styles from './styles.module.scss';

const iconNameToIcon: Record<IconName, FC<SVGProps<SVGSVGElement>>> = {
  showPass: ShowPassIcon,
  hidePass: HidePassIcon,
  error500: Error500,
  photo: Photo,
  telegramLogo: TelegramLogo,
  envelope: Envelope,

};

type Props = {
  name: IconName;
  className?: string;
};

const Icon: FC<Props> = ({ name, className }) => {
  const SelectedIcon = iconNameToIcon[name];

  return <SelectedIcon className={getValidClasses(styles.icon, className)} />;
};

export { Icon };
