enum CashDeskModalKey {
  TITLE = 'title',
  DATE = 'date',
  TIME = 'time',
  CASH_DESK_TYPE_LABEL ='cashDeskTypeLabel',
  CASH_DESK_CALC_METHOD_LABEL = 'calculationMethod',
  CASH_DESK_SUM_LABEL = 'cashDeskSumLabel',
  CASH_DESK_ASSIGNMENT = 'cashDeskAssignment',
  CASH_DESK_INFO = 'cashDeskInfo',
}

export { CashDeskModalKey };
