import { cilFeaturedPlaylist } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import cn from 'classnames';
import { AppTranslationKey , EmployeeFinInfoKey } from 'common/enums/enums';
import { useAppSelector } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const OrdersWidget: FC = () => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { t: tCommon } = useTranslation(AppTranslationKey.COMMON);
  const employee = useAppSelector(({ employees }) => employees.currentEmployee);

  return (
    <div className={ styles.smallWidget }>
      <div className={ styles.title }>
        { t(EmployeeFinInfoKey.TOTAL_DONE_ORDERS) }
      </div>
      <div className={ styles.description }>
        <span>
          <CIcon icon={ cilFeaturedPlaylist } className={ cn(styles.icon, 'icon-custom-size') } />
        </span>
        <span>
          { `${ employee?.totalOrders || 0 } ${ tCommon('pcs') }` }
        </span>
      </div>
    </div>
  );
};

export { OrdersWidget };
