import { CButton } from '@coreui/react-pro';
import { EmployeeModal } from 'components/modals/modals';
import { useAppDispatch, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { employeesActions } from 'store/actions';

type Props = {
  id: number,
  carServiceId: number,
  salaryDateFrom?: string,
  salaryDateTo?: string,
};

const IdCell: FC<Props> = ({ id, carServiceId, salaryDateFrom, salaryDateTo }) => {
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isDisableModal, setIsDisableModal] = useState<boolean>(false);

  const onOpenViewModal = (): void => {
    setIsOpenModal(true);
    setIsDisableModal(true);
  };

  const getListEmployees = (): void => {
    dispatch(employeesActions.getEmployees({
      carServiceId,
      salaryDateFrom,
      salaryDateTo,
    }));
  };

  return (
    <td>
      <CButton
        color="link"
        size="sm"
        className="px-0"
        onClick={ onOpenViewModal }
      >
        { id }
      </CButton>
      {
        isOpenModal && (
          <EmployeeModal
            isOpen={ true }
            carServiceId={ carServiceId }
            employeeId={ id }
            isDisable={ isDisableModal }
            onEnable={ (): void => setIsDisableModal(false) }
            onClose={ (): void => setIsOpenModal(false) }
            onAfterSubmit={ getListEmployees }
          />
        )
      }
    </td>
  );
};

export { IdCell };
