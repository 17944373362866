import {
  AppTranslationKey,
  CashDeskKey,
  DataStatus,
  LocalStorageKey,
  ModalFormType,
} from 'common/enums/enums';
import { CarServiceDto } from 'common/types/types';
import {
  NoDataWithAction,
  Spinner,
  usePermissionToastContext,
} from 'components/common/common';
import { FILTER_DATE_SHORT_MONTH_YEAR } from 'constants/date-formats';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext, useFilterContext } from 'contexts';
import { format, lastDayOfMonth, parse } from 'date-fns';
import {
  useAppDispatch,
  useAppSelector,
  useCashDeskModal,
  useEffect,
  useMemo,
} from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { cashDeskAction } from 'store/actions';
import { disableReadOnlyModalMode, enabledReadOnlyModalMode } from 'store/modal/reducer';

import { FilterPanel } from '../filter-panel';
import { CashOrdersList } from './cash-orders-list';

type Props = {
  activeCarService: CarServiceDto | null;
};

const CashOrders: FC<Props> = ({ activeCarService }) => {
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const { onPermissionAction } = usePermissionToastContext();
  const { getCashDeskModal, toggleCashDeskModal } =
    useCashDeskModal(activeCarService);
  const { t, i18n } = useTranslation([
    AppTranslationKey.CASH_DESK,
    AppTranslationKey.COMMON,
  ]);
  const { cashOrders, cashDeskDataStatus } = useAppSelector(({ cashDesk }) => ({
    cashOrders: cashDesk.cashOrders ?? [],
    cashDeskDataStatus: cashDesk.listCashOrdersUpdateStatus,
  }));
  const dispatch = useAppDispatch();
  const isCashDesksLoading = cashDeskDataStatus === DataStatus.PENDING;

  const { getValue } = useFilterContext();
  const period = getValue('period') as string;

  const { cashOrderDateFrom, cashOrderDateTo } = useMemo(() => {
    const value = parse(period, FILTER_DATE_SHORT_MONTH_YEAR, new Date());
    const cashOrderDateFrom = format(value, 'yyyyMM01');
    const cashOrderDateTo = format(lastDayOfMonth(value), 'yyyyMMdd');

    return { cashOrderDateFrom, cashOrderDateTo };
  }, [period]);

  //enable Extra Button
  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.CASH_DESK}:${CashDeskKey.ADD_CASH_DESK_BUTTON_TITLE}`,
      action: onPermissionAction(handledAddCashOrder, Tariffs.NO_TARIFF),
    });

    return () => {
      removeExtraButton();
      localStorage.removeItem(LocalStorageKey.SORTER_STATE);
    };
  }, [i18n.language]);

  useEffect(() => {
    dispatch(
      cashDeskAction.getCashOrdersByCarServiceId({
        carServiceId: activeCarService?.id ?? 0,
        dateTo: cashOrderDateTo,
        dateFrom: cashOrderDateFrom,
      }),
    );
  }, [activeCarService, cashOrderDateTo]);

  const handledAddCashOrder = (): void => {
    dispatch(disableReadOnlyModalMode(ModalFormType.CASH_ORDER_MODAL));
    toggleCashDeskModal(true);
  };

  if (isCashDesksLoading) {
    return <Spinner isOverflow containerHeight={150} />;
  }

  const handleClickCashOrderId = (id: number): void => {
    dispatch(cashDeskAction.getCashOrderById({ id }));
    dispatch(enabledReadOnlyModalMode(ModalFormType.CASH_ORDER_MODAL));
    toggleCashDeskModal(true);
  };

  return (
    <>
      <FilterPanel />
      {cashOrders.length === 0 ? (
        <NoDataWithAction
          actionName={t(CashDeskKey.ADD_CASH_DESK_BUTTON_TITLE)}
          title={t(CashDeskKey.NO_CASH_ORDERS_TITLE)}
          message={t(CashDeskKey.NO_CASH_ORDERS_TEXT)}
          callback={onPermissionAction(handledAddCashOrder, Tariffs.NO_TARIFF)}
        />
      ) : (
        <CashOrdersList
          activeCarServiceId={activeCarService?.id ?? 0}
          onClickId={handleClickCashOrderId}
        />
      )}

      {getCashDeskModal()}
    </>
  );
};

export { CashOrders };
