import { cilAt, cilPhone,cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody, CCardGroup,
  CCol,
  CContainer,
  CForm, CFormCheck,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro';
import ucsLogo from 'assets/img/ucs-logo.png';
import { AppRoute } from 'common/enums/app/app-route.enum';
import { DataStatus } from 'common/enums/app/data-status.enum';
import { AppTranslationKey } from 'common/enums/enums';
import { UserSignUpRequestDto } from 'common/types/types';
import { PhoneInputValid } from 'components/common/common';
import { Spinner } from 'components/common/spinner/spinner';
import { GdprModal } from 'components/modals/modals';
import { PasswordInput } from 'components/password-input/password-input';
import { getValidClasses } from 'helpers/helpers';
import { useAppSelector, useState } from 'hooks/hooks';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ContactUs } from '../components';
import { DEFAULT_SIGN_UP_PAYLOAD } from './common';
import styles from './style.module.scss';

interface ISignUpForm extends UserSignUpRequestDto {
  agree: string,
}

type Props = {
  onSubmit: (payload: UserSignUpRequestDto) => void
};

const SignUpForm: FC<Props> = ({ onSubmit }) => {

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<ISignUpForm>({
    defaultValues: DEFAULT_SIGN_UP_PAYLOAD,
  });

  const [ isGdrpOpen, setIsGdrpOpen ] = useState<boolean>(false);
  const { dataStatus } = useAppSelector((state) => state.auth);
  const { t } = useTranslation([AppTranslationKey.AUTH, AppTranslationKey.COMMON]);
  const isLoading = dataStatus === DataStatus.PENDING;

  const handleCloseGdrpModal = (): void => {
    setIsGdrpOpen(false);
  };

  const handleOpenGdrpModal = (): void => {
    setIsGdrpOpen(true);
  };

  const setPhoneValue = (value: string): void => {
    setValue('phone', value, { shouldValidate: true });
  };

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9}>
            <CCardGroup>
              <CCard className="p-3">
                <CCardBody className="px-4 py-0">
                  <CForm autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <h1 style={{ textAlign: 'center', paddingBottom: 20 }}>{t('register')}</h1>
                    <p style={{ fontSize: 16, paddingBottom: 5 }}>{t('createAccount')}</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilAt}/>
                      </CInputGroupText>
                      <CFormInput
                        {...register('username',
                          {
                            required: t('loginErrorText') ?? 'Please enter a valid email address',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: t('loginErrorText') ?? 'Please enter a valid email address',
                            },
                          })
                        }
                        placeholder={t('login') ?? 'E-mail'}
                        type="text"
                        name="username"
                        invalid={Boolean(errors.username)}
                        feedbackInvalid={errors.username?.message}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser}/>
                      </CInputGroupText>
                      <CFormInput
                        {...register('name', {
                          required: t('requiredName') ?? 'Name is required',
                        })}
                        name="name"
                        placeholder={t('name') ?? 'Full Name'}
                        invalid={Boolean(errors.name)}
                        feedbackInvalid={errors.name?.message}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilPhone}/>
                      </CInputGroupText>
                      <PhoneInputValid
                        {...register('phone')}
                        disabled={false}
                        name="phone"
                        onChange={setPhoneValue}
                        feedbackInvalid={t('maxPhoneLengthError', { ns: AppTranslationKey.COMMON })}
                        placeholder={t('phone') ?? 'Phone'}
                      />
                    </CInputGroup>
                    <PasswordInput
                      {...register('password', {
                        required: t('requiredPassword') ?? 'Password is required',
                      })}
                      placeholder={t('password') ?? 'Password'}
                      invalid={Boolean(errors.password)}
                      feedbackInvalid={errors.password?.message}
                    />
                    <CFormCheck
                      {...register('agree', {
                        validate: (value) => value ||
                        (t('agreeToUsePersonalDataError') ?? 'Consent to data processing is required'),
                      })}
                      className="mt-3"
                      label={<CButton
                        color="link"
                        className={getValidClasses(styles.gdprLink, 'p-0')}
                        onClick={handleOpenGdrpModal}
                      >
                        {t('agreeToUsePersonalData') ?? 'I agree to the processing of my personal data'}
                      </CButton>}
                      feedbackInvalid={errors.agree?.message}
                      invalid={Boolean(errors.agree)}
                    />
                    <div className="d-grid mt-4">
                      <CButton
                        color="success"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading && (<Spinner color="black"/>)}
                        {t('createAccountButtonText') ?? 'Create Account'}</CButton>
                    </div>
                    <Link to={AppRoute.SIGN_IN} className="d-grid">
                      <CButton color="link" className="mt-3">
                        {t('backToSignIn') ?? 'Back to Sign In'}
                      </CButton>
                    </Link>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary p-3" style={ { width: '100%' } }>
                <CCardBody className="text-center">
                  <div style={{ fontSize: 16 }}>
                    <img className={styles.ucsLogo} src={ucsLogo} alt={'UCS Logo'}/>
                    <p>
                      {t('crmTitle1')}
                    </p>
                    <p style={{ borderBottom: 1, borderBottomStyle: 'solid', paddingBottom: 10, margin: 0 }}>
                      {t('crmTitle2')}
                    </p>
                    <p>
                      {t('crmTitle3')}
                    </p>
                    <p style={{ borderBottom: 1, borderBottomStyle: 'solid', paddingBottom: 10, margin: 0 }}>
                      {t('crmTitle4')}
                    </p>
                    <div style={{ textAlign: 'start', display: 'flex', flexDirection: 'column' }}>
                      <p style={{ paddingTop: 15 }}>{t('crmOptions')}</p>
                      <span>✓ {t('option1')}</span>
                      <span>✓ {t('option2')}</span>
                      <span>✓ {t('option3')}</span>
                      <span style={{ borderBottom: 1, borderBottomStyle: 'solid', paddingBottom: 10 }}>
                      ✓ {t('option4')}
                      </span>
                    </div>
                    <div className="pt-3">
                      <p>{t('anyQuestion')}</p>
                      <span>{t('callMe')}</span>
                    </div>
                    <ContactUs />
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>

          </CCol>
        </CRow>
      </CContainer>
      <GdprModal isOpen={isGdrpOpen} onCloseModal={handleCloseGdrpModal}/>
    </div>
  );
};

export { SignUpForm };
