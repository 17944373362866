import { FC } from 'react';

import { ApplyButton } from './apply-button';
import { CurrentWeekField } from './current-week-field';
import { LastWeekField } from './last-week-field';
import { PeriodField } from './period-field';
import { RangeDateField } from './range-date-field';
import styles from './styles.module.scss';
import { TodayField } from './today-field';

interface IFilterPanelProps {
  employeeId: number,
  carServiceId: number,
}

const FilterPanel: FC<IFilterPanelProps> = (props) => (
  <div className={ styles.filterContainer }>
    <PeriodField />
    <RangeDateField />
    <LastWeekField />
    <TodayField />
    <CurrentWeekField />
    <ApplyButton { ...props } />
  </div>
);

export { FilterPanel };
