import { CButton, CModalFooter } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import noop from 'lodash/noop';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.scss';

type Props = {
  isDisable: boolean,
  isSubmitting: boolean,
  onEnable?: () => void,
  onClose: () => void,
};

const ModalFooter: FC<Props> = ({ isDisable, isSubmitting, onClose, onEnable = noop }) => {
  const { t } = useTranslation(AppTranslationKey.COMMON);

  return (
    <CModalFooter className={styles.modalFooter}>
      <CButton color="dark" variant="ghost" onClick={onClose} type="button">
        {t('closeBtn')}
      </CButton>
      {
        isDisable && (
          <CButton color="danger" onClick={ onEnable } type="button">
            {t('editButton')}
          </CButton>
        )
      }
      {
        isDisable || (
          <CButton color="primary" type="submit" disabled={ isSubmitting }>
            {t('saveBtn')}
          </CButton>
        )
      }
    </CModalFooter>
  );
};

export { ModalFooter };
