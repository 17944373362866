enum CashDeskKey {
  TITLE = 'title',
  ADD_CASH_DESK_BUTTON_TITLE = 'addCashDeskButtonTitle',
  NO_CASH_ORDERS_TITLE = 'noCashOrdersTitle',
  NO_CASH_ORDERS_TEXT = 'noCashOrdersText',
  MODAL = 'modal',
  SUCCESS_ADDED = 'successAdded',
  CASH_ORDERS_TABLE = 'cashOrdersTable',
  SUCCESS_UPDATED = 'successUpdated',
}

export { CashDeskKey };
