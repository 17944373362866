import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t as tt } from 'i18next';

const translate = (field: string): string => {
  return tt(`${ AppTranslationKey.EMPLOYEES_PROFILES }:${EmployeesProfilesKey.COLUMNS}.${ field }`);
};

const getTableColumn = (): TableColumn[] => [
  {
    key: 'id',
    label: '#',
    filter: false,
    sorter: true,
    _style: { width: '7%' },
  },
  {
    key: 'fullName',
    label: translate(EmployeesProfilesKey.FULL_NAME),
    filter: false,
    sorter: true,
  },
  {
    key: 'position',
    label: translate(EmployeesProfilesKey.POSITION),
    filter: false,
    sorter: true,
    _style: { width: '15%' },
  },
  {
    key: 'phone',
    label: translate(EmployeesProfilesKey.PHONE),
    filter: false,
    sorter: false,
    _style: { width: '15%' },
  },
  {
    key: 'info',
    label: translate(EmployeesProfilesKey.ADD_INFO),
    filter: false,
    sorter: false,
    _style: { width: '25%' },
  },
  {
    key: 'status',
    label: translate(EmployeesProfilesKey.STATUS),
    filter: false,
    sorter: true,
    _style: { width: '15%' },
  },
  {
    key: 'showDetails',
    label: '',
    _style: { width: '1%' },
    filter: false,
    sorter: false,
  },
];

export { getTableColumn };
