import { CFormSwitch } from '@coreui/react-pro';
import { AppTranslationKey, EmployeeFinInfoKey } from 'common/enums/enums';
import { useFilterContext } from 'contexts';
import { getPeriodCurrentWeek } from 'helpers/helpers';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CurrentWeekField: FC = () => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { onChangeValues, getValue } = useFilterContext();
  const isCurrentWeek = getValue('isCurrentWeek') || false;

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;

    onChangeValues({
      isLastWeek: false,
      isToday: false,
      isCurrentWeek: checked,
      period: null,
      rangeDate: getPeriodCurrentWeek(),
    });
  };

  return (
    <CFormSwitch
      size="lg"
      label={ t(`${EmployeeFinInfoKey.FILTERS}.${EmployeeFinInfoKey.CURRENT_WEEK}`) }
      checked={ isCurrentWeek as boolean }
      onChange={ onChangeValue }
    />
  );
};

export { CurrentWeekField };
