import {
  LocalStorageKey,
  Pages,
} from 'common/enums/enums';
import { FiltersData, WithCarServicesTabs } from 'common/types/types';
import { FilterContextProvider } from 'contexts';
import { withCarServicesTabs } from 'hoc/hoc';
import { t as t1 } from 'i18next';
import React, { FC } from 'react';

import { CashOrders } from './components/cash-orders';
import { cashOrderFilterInitialData } from './components/cash-orders/common';

const CashDesk: FC<WithCarServicesTabs> = ({ activeCarService }) => {

  return (
    <FilterContextProvider<FiltersData>
      storageKey={ activeCarService?.id ?? 0 }
      storageName={ LocalStorageKey.CASH_ORDER_FILTERS }
      initialValue={ cashOrderFilterInitialData }>
      <CashOrders activeCarService={activeCarService} />
    </FilterContextProvider>
  );
};

export const CashDeskWithCarServicesTabs =
  withCarServicesTabs(
    CashDesk,
    false,
    Pages.CASH_DESK,
    () => t1('mainMenu:cashDesk') ?? '',
  );
