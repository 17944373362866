import { CForm, CModalBody } from '@coreui/react-pro';
import { EmployeeDto, EmployeeFormData } from 'common/types/types';
import { parse } from 'date-fns';
import { getFormattedDate } from 'helpers/helpers';
import { useAppDispatch, useEffect, useForm, useState } from 'hooks/hooks';
import noop from 'lodash/noop';
import React, { FC } from 'react';
import { employeesActions } from 'store/actions';

import {
  AddInfoField,
  BirthdayField,
  DismissalDateField,
  EmailField,
  NameField,
  PatronymicField,
  PhoneField,
  PositionField,
  RegistrationDateField,
  SalaryField,
  StatusField,
  SurnameField,
  TelegramField,
  ViberField,
} from './fields';
import { ModalFooter } from './modal-footer';

type Props = {
  carServiceId: number,
  employeeId?: number,
  data: EmployeeFormData,
  isDisable: boolean,
  onEnable?: () => void,
  onClose: () => void,
  onAfterSubmit?: () => void,
};

const EmployeeForm: FC<Props> = ({
  carServiceId,
  employeeId,
  data,
  isDisable,
  onEnable,
  onClose,
  onAfterSubmit = noop,
}) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<EmployeeFormData>({
    defaultValues: data,
  });

  const statusWatch = +watch('statusId');
  const infoWatch = watch('employeeInfo') || '';
  const dismissalDateWatch = watch('dismissalDate');

  const [requiredDismissalDate, setRequiredDismissalDate] = useState( statusWatch === 5 );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setRequiredDismissalDate(statusWatch === 5);
  }, [statusWatch]);

  useEffect(() => {
    trigger(['dismissalDate', 'statusId'], { shouldFocus: true });
  }, [requiredDismissalDate]);

  useEffect(() => {
    trigger('statusId', { shouldFocus: true });
  }, [dismissalDateWatch]);

  useEffect(() => {
    reset(data);
  }, [data]);

  const setPhoneValue = (value: string): void => {
    setValue('phone.value', value, { shouldValidate: true });
  };

  const setViberValue = (value: string): void => {
    setValue('viber.value', value, { shouldValidate: true });
  };

  const onSubmit = (data: EmployeeFormData): void => {
    setIsSubmitting(true);

    const payload: EmployeeDto = {
      carServiceId,
      firstName: data.firstName,
      secondName: data.secondName,
      patronymicName: data.patronymicName,
      birthDate: data.birthDate
        ? getFormattedDate(parse(data.birthDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
        : null,
      registrationDate: getFormattedDate(parse(data.registrationDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd'),
      dismissalDate: data.dismissalDate && requiredDismissalDate
        ? getFormattedDate(parse(data.dismissalDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
        : null,
      employeeStatusId: +data.statusId,
      employeePositionId: +data.positionId,
      employeeInfo: data.employeeInfo,
      employeeContactList: [
        { contactTypeId: 1, contactData: data.phone.value, contactId: data.phone.contactId },
        { contactTypeId: 2, contactData: data.email.value, contactId: data.email.contactId },
        { contactTypeId: 4, contactData: data.telegram.value, contactId: data.telegram.contactId },
        { contactTypeId: 5, contactData: data.viber.value, contactId: data.viber.contactId },
      ],
      employeeSalaryDataList: data.salaryTypes.map((item) => ({
        rowId: item.rowId,
        salaryTypeId: item.salaryTypeId,
        salaryTypeValue: item.salaryTypeValue ? +item.salaryTypeValue : 0,
      })),
    };

    if (employeeId) {
      dispatch(employeesActions.updateEmployee({ ...payload, employeeId }))
        .then(() => onAfterSubmit())
        .then(() => setIsSubmitting(false))
        .then(() => onClose());
    } else {
      dispatch(employeesActions.createEmployee(payload))
        .then(() => onAfterSubmit())
        .then(() => setIsSubmitting(false))
        .then(() => onClose());
    }
  };

  return (
    <CForm onSubmit={ handleSubmit(onSubmit) } className="form-scroll">
      <CModalBody>
        <NameField register={ register } errors={ errors } isDisable={ isDisable } />
        <SurnameField register={ register } errors={ errors } isDisable={ isDisable } />
        <PatronymicField register={ register } isDisable={ isDisable } />
        <PositionField register={ register } errors={ errors } isDisable={ isDisable } />
        <StatusField
          register={ register }
          errors={ errors }
          isDisable={ isDisable }
          dismissalDate={ dismissalDateWatch }
        />
        <RegistrationDateField control={ control } isDisable={ isDisable } />
        <DismissalDateField control={ control } isDisable={ isDisable } isRequired={ requiredDismissalDate } />
        <SalaryField register={ register } control={ control } errors={ errors } isDisable={ isDisable } />
        <BirthdayField control={ control } isDisable={ isDisable } />
        <PhoneField register={ register } isDisable={ isDisable }  onChange={setPhoneValue}/>
        <TelegramField register={ register } isDisable={ isDisable } />
        <ViberField register={ register } isDisable={ isDisable } onChange={setViberValue} />
        <EmailField register={ register } errors={ errors } isDisable={ isDisable } />
        <AddInfoField register={ register } value={ infoWatch } isDisable={ isDisable } />
      </CModalBody>
      <ModalFooter onClose={ onClose } isDisable={ isDisable } onEnable={ onEnable } isSubmitting={ isSubmitting } />
    </CForm>
  );
};

export { EmployeeForm };
