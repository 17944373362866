import { CFormSwitch } from '@coreui/react-pro';
import { AppTranslationKey, EmployeeFinInfoKey } from 'common/enums/enums';
import { useFilterContext } from 'contexts';
import { getPeriodToday } from 'helpers/helpers';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const TodayField: FC = () => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { onChangeValues, getValue } = useFilterContext();
  const isToday = getValue('isToday') || false;

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;

    onChangeValues({
      isLastWeek: false,
      isToday: checked,
      isCurrentWeek: false,
      period: null,
      rangeDate: getPeriodToday(),
    });
  };

  return (
    <CFormSwitch
      size="lg"
      label={ t(`${EmployeeFinInfoKey.FILTERS}.${EmployeeFinInfoKey.TODAY}`) }
      checked={ isToday as boolean }
      onChange={ onChangeValue }
    />
  );
};

export { TodayField };
