import { CCol, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/translation-key/app-translation-key.enum';
import { CarModalFormData, Color } from 'common/types/types';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<CarModalFormData>,
  colors: Color[],
  disabled?: boolean,
};

const ColorField: FC<Props> = ({ disabled = false, register, colors }) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="color" className="col-sm-3 col-form-label">
        {t('color')}
      </CFormLabel>
      <CCol sm={9}>
        <CFormSelect
          disabled={disabled}
          { ...register('carColorId') }
          id="color"
        >
          <option key={-1} value={-1}>{disabled ? '' : t('selectColor')}</option>
          {
            colors.map((color) => (
              <option key={color.id} value={color.id}>
                {color.name}
              </option>
            ))
          }
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export { ColorField };
