import infoFinIcon from 'assets/icons/fin.png';
import { AppRoute, AppTranslationKey, EmployeesRoute, SalaryKey } from 'common/enums/enums';
import { SalaryTableRow } from 'common/types/types';
import { useNavigate } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import styles from './styles.module.scss';

interface IFinInfoCellProps extends SalaryTableRow {
  carServiceId: number,
}

const FinInfoCell: FC<IFinInfoCellProps> = ({ id, carServiceId }) => {
  const { t } = useTranslation(AppTranslationKey.SALARY);
  const navigate = useNavigate();
  const idTooltip = `finInfoCell_${ id }`;
  const contentTooltip = t(`${SalaryKey.COLUMNS}.${SalaryKey.FIN_INFO_CELL_TOOLTIP}`, { id });

  const openFinInfoPage = (): void => {
    navigate(`${ AppRoute.EMPLOYEES }/${ EmployeesRoute.FIN_INFO }/${ id }?carServiceId=${ carServiceId }`);
  };

  return (
    <>
      <img
        className={styles.finInfoCell}
        src={infoFinIcon}
        alt="FinInfo"
        data-tooltip-id={ idTooltip }
        onClick={ openFinInfoPage }
      />
      <Tooltip
        id={ idTooltip }
        content={ contentTooltip }
        place="bottom-start"
      />
    </>
  );
};

export { FinInfoCell };
