enum ConfirmFormDataFields {
  VIN_CODE = 'vin',
  CREATE_YEAR = 'createYear',
  CAR_BRAND = 'brand',
  MODEL = 'model',
  COLOR = 'color',
  CAR_BODY_TYPE_NAME = 'bodyType',
  CAPACITY = 'capacity',
  REG_NUM = 'regNum',
  FUEL = 'fuelType',
}

export { ConfirmFormDataFields };

