import {
  AllCarWorkModalTranslationKey,
  AppTranslationKey,
  AuthTranslationKey,
  CarWorkTranslationKey, CashDeskKey, CashDeskModalKey, CashOrdersTableKey, ClientSparePartsTranslationKey,
  DangerAlertModalTranslationKey, EmployeeFinInfoKey, EmployeesProfilesKey,
  ErrorPageTranslationKey, FeedBackModalKey,
  GdrpTranslationKey, HelpAboutRequestKey,
  InfoVinTranslationKey,
  NotFoundPageTranslationKey,
  OrderCertificateKey,
  OrderModalTranslationKey,
  RecoveryPasswordTranslationKey, SalaryKey, SelectComponentKey,
  SelectOrderModalTranslationKey,
  SelectSparePartsModalTranslationKey,
  SelectSparePartsTableTranslationKey,
  SelectWorkModalTranslationKey,
  SelectWorkToOrderModalTranslationKey,
  StorageDetailsTranslationKey,
  StorageTranslationKey,
  WorkOrderModalTranslationKey,
  WorkOrderTranslationKey,
} from 'common/enums/enums';

export const ru = {
  breadcrumbs: {
    home: 'Домашняя страница',
    dashboard: 'Главная страница',
    profile: 'Профиль',
    carService: 'СТО',
    edit: 'Редактирование',
    add: 'Добавление',
    myAutoServices: 'Мои СТО',
    carServicesProfiles: 'Профили СТО',
    carServicesCustomers: 'Клиенты',
    carServicesAuto: 'Автомобили',
    carServicesOrders: 'Заявки',
    storage: 'Состав',
    suppliers: 'Поставщики',
    cashDesk: 'Касса',
    storagesProfiles: 'Профили складов',
    work: 'Работы',
    spareParts: 'Запчасти',
    workOrders: 'Наряды',
    videoReview: 'Видеообзор',
    tariffs: 'Тарифы',
    services: 'Сервисы',
    infoVin: 'Информация о VIN',
    orderCertificate: 'Акт выполненных работ #{{orderId}}',
    help: 'Справка',
    allAboutRequest: 'Все про Заявку',
    employees: 'Сотрудники',
    salary: 'Зарплата',
    employeesProfiles: 'Профили сотрудников',
    finInfo: 'Информация по сотруднику #{{employeeId}}',
  },
  mainMenu: {
    dashboard: 'Главная страница',
    myAutoServices: 'Мои СТО',
    ordersNavItem: 'Заявки',
    carsNavItem: 'Автомобили',
    customersNavItem: 'Клиенты',
    carServicesNavItem: 'Профили СТО',
    profile: 'Профиль',
    exit: 'Выход',
    storage: 'Склад',
    parts: 'Запчасти',
    work: 'Работы',
    suppliers: 'Поставщики',
    storageProfiles: 'Профили складов',
    workOrders: 'Наряды',
    videoReview: 'Видеообзор',
    tariffs: 'Тарифы',
    services: 'Сервисы',
    infoVin: 'Информация по VIN',
    help: 'Справка',
    allAboutRequest: 'Все про Заявку',
    employees: 'Сотрудники',
    salary: 'Зарплата',
    employeesProfiles: 'Профили сотрудников',
    cashDesk: 'Касса',
    langSelector: 'Язык интерфейса',
  },
  [AppTranslationKey.AUTH]: {
    titleSignIn: 'Вход',
    subtitleSignIn: 'Войдите, чтобы продолжить',
    secondSubtitleSignIn: 'Онлайн платформа для управления автосервисом',
    anyQuestion: 'Возникли вопросы или интересует дополнительная информация ?',
    crmTitle1: 'Возьмите контроль над своим СТО:',
    crmTitle2: 'ERP Система для эффективного ведения бизнеса',
    crmTitle3: 'Не хватает времени на развитие СТО?',
    crmTitle4: 'ERP Система – это ваше решение!',
    crmOptions: 'Благодаря ей Вы сможете:',
    option1: 'Добавлять заявки от клиентов;',
    option2: 'Создавать Наряд-заказ;',
    option3: 'Вести учет запчастей на складе;',
    option4: 'Получить 30 дней БЕСПЛАТНОГО безлимита после регистрации.',
    callMe: 'Позвоните или напишите нам',
    loginButtonText: 'Войти',
    forgotPassword: 'Забыли пароль?',
    welcomeText: 'Добро пожаловать',
    dontHaveAccount: 'Нет учетной записи?',
    registerNowButtonText: 'Регистрация',
    loginErrorText: 'Укажите действительную электронную почту',
    passwordErrorText: 'Пароль не может быть пустым',
    register: 'Регистрация',
    createAccount: 'Создайте свой аккаунт',
    login: 'Электронная почта',
    name: 'Имя Фамилия',
    password: 'Пароль',
    phone: 'Телефон',
    repeatPassword: 'Повторите пароль',
    createAccountButtonText: 'Создать аккаунт',
    backToSignIn: 'Назад до входу',
    requiredPassword: 'Необходимо ввести пароль',
    requiredName: 'Необходимо ввести имя',
    agreeToUsePersonalData: 'Согласен на обработку моих персональных данных',
    agreeToUsePersonalDataError: 'Необходимо согласие на обработку данных',
    machPasswordError: 'Пароли не совпадают',
    [AuthTranslationKey.REGISTRATION_MSG]: 'Поздравляем! Ваша учетная запись успешно создана. ' +
    'Для входа в систему подтвердите свой e-mail. Пожалуйста, проверьте указанную вами при регистрации почту. ' +
    'Если вы не получили письмо, проверьте папку "спам". При необходимости вы можете | ' +
    ' Если возникли трудности с регистрацией или входом напишите ' +
    'нам | даем ответ в течение 20 - 30 минут.',
    [AuthTranslationKey.RESEND_ACTION_NAME]: 'отправить письмо повторно.',
    [AuthTranslationKey.INACTIVE_USER__MSG] :'Ваша учетная запись не активирована. Для входа в систему ' +
    'подтвердите свой e-mail. Мы уже отправили письмо на вашу электронную почту. Если вы не получили письмо, ' +
    'проверьте папку "спам". При необходимости вы можете | ' +
    ' Если возникли трудности с регистрацией или входом напишите ' +
    'нам | даем ответ в течение 20 – 30 минут.',
    [AuthTranslationKey.ACTIVATED]: 'Ваш аккаунт активовано',
    [AuthTranslationKey.RESEND_VALIDATION_SUCCESS]: 'Письмо отправлено',
  },
  [AppTranslationKey.CAR_SERVICE]: {
    allCarServiceText: 'Все СТО',
    backCaptionButton: 'Назад',
    saveCaptionButton: 'Сохранить',
    addCarServiceCaptionButton: 'Добавить СТО',
    editCarServiceTitle: 'Редактирование СТО',
    addCarServiceTitle: 'Добавление СТО',
    noCarServiceTitle: 'У вас нет ни одного СТО :(',
    noCarServiceText: 'Нажмите кнопку "Добавить СТО", чтобы создать его',
    carServiceFormFieldName: 'Название*',
    carServiceFormFieldStatus: 'Статус*',
    carServiceFormFieldNameErrorRequired: 'Это обязательное поле',
    carServiceFormFieldAddress: 'Адрес*',
    carServiceFormFieldAddressErrorRequired: 'Это обязательное поле',
    carServiceFormFieldInfo: 'Описание',
    carServiceFormFieldInfoText: 'Осталось {{countSymbols}} символов',
    carServiceFormFieldInfoErrorMaxLength: 'Слишком много символов',
    errorMessageNoCarService: 'Сначала необходимо создать СТО',
    carServiceUpdate: 'СТО успешно обновлено',
    carServiceAdd: 'СТО успешно добавлено',
    infoCardTitle: 'Информация',
    logo: 'Логотип',
    addLogoButton: 'Добавить файл',
    removeLogoButton: 'Удалить',
    fileOversizeError: 'Размер файла должен быть меньше 25Мб',
    contactsCardTitle: 'Контакты',
    mainPhone: 'Телефон (Основной)',
    phone: 'Телефон (Дополнительный)',
    viber: 'Viber',
    telegram: 'Telegram',
    email: 'E-mail',
    detailsCardTitle: 'Реквизиты',
    recipient: 'Наименование получателя',
    ipn: 'ИНН',
    legalAddress: 'Юридический адрес',
    edrpou: 'ЕГРПОУ',
    account: 'Расчетный счет (р/р, IBAN)',
    mfo: 'МФО',
    bank: 'Банк',
  },
  carServiceDetails: {
    carServiceDetailsName: 'Название:',
    carServiceDetailsStatus: 'Статус:',
    carServiceDetailsAddress: 'Адрес:',
    carServiceDetailsInfo: 'Описание:',
    carServiceDetailsEditCaptionButton: 'Редактировать',
    carServiceDetailsSaveCaptionButton: 'Сохранить',
  },
  [AppTranslationKey.CAR_SERVICE_ORDER]: {
    title: 'Заявки',
    orderAccordionHeader: 'Заявки на ремонт',
    profileAccordionHeader: 'Профиль СТО',
    autoAccordionHeader: 'Автомобили',
    customerAccordionHeader: 'Клиенты',
    addOrderCaptionButton: 'Добавить заявку',
    showDetailsCaption: 'Раскрыть',
    hideDetailsCaption: 'Скрыть',
    orderTableColumns: '#|Авто|Дата и Время|Статус|Комментарии|Сумма|Сумма с нарядами|Сумма без нарядов',
    orderProblemTitle: 'Работы',
    orderSparePartsTitle: 'Запчасти',
    orderCommentTitle: 'Комментарии',
    orderPerformer: 'Исполнитель',
    orderViewButton: 'Просмотр',
    orderEditButton: 'Редактировать',
    order: 'Заявка',
    orderAuto: 'Авто',
    noOrdersTitle: 'У вас нет заявок',
    noOrdersText: 'Нажмите кнопку "Добавить заявку", чтобы создать ее',
    itemsPerPageLabel: 'Элементов на странице',
    successEditOrderMes: 'Заявка успешно обновлена',
    openWorkErrorMes: 'Невозможно закрыть заявку, по которой есть невыполненные работы.',
    successAddOrderMes: 'Заявка успешно добавлена',
    defPerformer: 'Без исполнителя',
    filters: {
      today: 'Сегодня',
      currentWeek: 'Текущая неделя',
      currentMonth: 'Текущий месяц',
      startDay: 'Дата начала',
      endDay: 'Дата окончания',
      status: 'Статус...',
      apply: 'Применить',
      selectAllOptions: 'Выбрать все параметры',
    },
    orderNoWork: 'Заявка не имеет работ',
    orderNoSpareParts: 'Заявка не имеет запчастей',
    orderNoUserSpareParts: 'Заявка не имеет запчастей от заказчика',
  },
  shortDayOfWeek: {
    sun: 'Вс',
    mon: 'Пн',
    tues: 'Вт',
    wed: 'Ср',
    thurs: 'Чт',
    fri: 'Пт',
    sat: 'Сб',
  },
  [AppTranslationKey.ORDER_MODAL]: {
    modalTitle: 'Заявка',
    autoFieldLabel: ' Автомобиль*',
    requiredFormField: 'Это обязательное поле',
    dateFieldLabel: 'Плановая дата*',
    timeFieldLabel: 'Плановое время*',
    performerFieldLabel: 'Исполнитель',
    shallDoFieldLabel: 'Работы',
    spareParts: 'Запчасти',
    userSpareParts: 'Запчасти от заказчика',
    duties: 'Наряды',
    statusFieldLabel: 'Статус',
    commentsFieldLabel: 'Комментарий',
    editButtonCation: 'Редактировать',
    saveButtonCaption: 'Сохранить',
    closeButtonCaption: 'Закрыть',
    newBtn: 'Новый',
    selectBtn: 'Выбрать',
    selectCar: 'Выберите автомобиль ...',
    noSelectCar: 'Авто не выбрано',
    workName: 'Название работы',
    numberTourOfDuty: 'Наряд',
    performer: 'Исполнитель',
    status: 'Статус',
    [OrderModalTranslationKey.ORDER_NO_WORK]: 'Заявка не имеет работ',
    [OrderModalTranslationKey.ADD_NEW_WORK_ORDER]: 'Добавить наряд',
    [OrderModalTranslationKey.TOTAL_SUM_ORDER]: 'Сумма заявки',
    [OrderModalTranslationKey.TOTAL_SUM_WORKS_OF_ORDER]: 'Сумма работ',
    [OrderModalTranslationKey.TOTAL_SUM_SPARE_PARTS_OF_ORDER]: 'Сумма запчастей',

  },
  [AppTranslationKey.CAR_MODAL]: {
    modalTitle: 'Автомобиль',
    vin: 'VIN',
    brand: 'Бренд',
    model: 'Модель',
    bodyType: 'Тип кузова',
    fuelType: 'Тип топлива',
    color: 'Цвет',
    regNum: 'Регистрационный номер',
    createYear: 'Год выпуска',
    mileageKm: 'Пробег (км)',
    carInfo: 'Дополнительная информация',
    saveButtonCaption: 'Сохранить',
    selectBrand: 'Выберите бренд ...',
    selectModel: 'Выберите модель ...',
    selectBodyType: 'Выберите тип кузова ...',
    selectColor: 'Выберите цвет ...',
    selectFuelType: 'Выберите тип топлива ...',
    successCreateCarMes: 'Автомобиль успешно добавлен',
    successModifyCarMes: 'Автомобиль успешно обновлен',
    requiredFormField: 'Это обязательное поле',
    closeButtonCaption: 'Закрыть',
    editButtonCation: 'Редактировать',
    modalListTitle: 'Автомобили',
    addNewCarBtn: 'Добавить новый автомобиль',
    selectBtn: 'Выбрать',
    findBtnText: 'Поиск',
    searchBtnErrorText: 'Введите регистрационный номер и повторите поиск',
    table: {
      regNum: 'Гос. №',
      brand: 'Марка',
      model: 'Модель',
      info: 'Доп. инфо',
    },
    selectCarText: 'Выбрано: [{{ car }}]',
    noSelectCarText: 'Автомобиль не выбран',
    capacity: 'Объем двигателя (см³)',
    fillFormModalHeader: 'Обновление данных автомобиля',
    fillFormModalFooter: 'Выберите данные, которые Вы хотите обновить или добавить. ' +
      'По умолчанию выбраны все поля, но Вы можете оставить только те, что требуют изменений.',
    fillFormModalBody: 'Информация об автомобиле по вашему запросу успешно найдена: ',
    fillFormBtnClose: 'Закрыть',
    fillFormBtnConfirm: 'Заполнить пустые',
    fillFormBtnConfirmAll: 'Изменить',
    errorSelectBrandMessage: 'Сперва необходимо выбрать бренд автомобиля',
  },
  [AppTranslationKey.PROFILE]: {
    title: 'Профиль',
    login: 'Логин:',
    name: 'Имя Фамилия:',
    dateProfile: 'Дата регистрации:',
    tariffName: 'Текущий тариф:',
    tariffStatus: 'Статус:',
    tariffStart: 'Дата подключения:',
    tariffEnd: 'Дата окончания:',
    changePassword: 'Сменить пароль',
    selectTariff: 'Выбрать тариф',
    noData: 'Нет информации',
    activeStatus: 'Активный',
    paidStatus: 'Оплаченый',
    endStatus: 'Закончился',
    requiredName: 'Необходимо ввести имя',
    errorPhone: 'Недействительный номер телефону',
    phone: 'Телефон:',
    changePasswordButton: 'Сменить пароль',
    changePasswordModal: {
      title: 'Замена пароля авторизации',
      saveButton: 'Изменить',
      closeButton: 'Закрить',
      oldPasswordTitle: 'Введите старый пароль',
      newPasswordTitle: 'Введите новый пароль',
      repeatNewPasswordTitle: 'Повторите пароль',
      passwordPlaceholder: 'Пароль',
      passwordErrorText: 'Пароль не может быть пустым',
      matchNewPasswordError: 'Пароль совпадает с текущим',
      matchPasswordError: 'Пароли не совпадают',
      successMessage: 'Пароль изменено',
    },
  },
  [AppTranslationKey.CAR_SERVICE_AUTO]: {
    title: 'Автомобили',
    addCarCaptionButton: 'Добавить автомобиль',
    noCarsTitle: 'У вас нет машин',
    noCarsText: 'Нажмите кнопку «Добавить автомобиль», чтобы создать его',
    carTableColumns: '#|Гос. №|Марка|Модель|Цвет|VIN',
    showDetailsCaption: 'Раскрыть',
    hideDetailsCaption: 'Скрыть',
    carBodyTypeName: 'Тип кузова',
    createYear: 'Год выпуска',
    mileageKm: 'Пробег в км',
    carInfo: 'Дополнительная инф.',
    itemsPerPageLabel: 'Элементов на странице',
    vinCode: 'vin-код',
    carViewButton: 'Просмотр',
    carEditButton: 'Редактировать',
    capacity: 'Объем двигателя (см³)',
    fuelType: 'Тип топлива',
  },
  [AppTranslationKey.CAR_SERVICE_CUSTOMER]: {
    addCustomerCaptionButton: 'Добавить клиента',
    noCustomersTitle: 'У вас нет клиентов',
    noCustomersText: 'Нажмите кнопку "Добавить клиента", чтобы создать его',
  },
  widgets: {
    title: 'Рабочий стол и аналитика',
    countAllTask: 'Количество заявок',
    countOpenTask: 'Количество открытых заявок ',
    countAllCars: 'Количество авто',
  },
  [AppTranslationKey.RECOVERY_PASSWORD]: {
    [RecoveryPasswordTranslationKey.TITLE_RECOVERY_PASSWORD]: 'Восстановление пароля',
    [RecoveryPasswordTranslationKey.SUBTITLE_RECOVERY_PASSWORD]: 'Введите свой адрес электронной почты',
    [RecoveryPasswordTranslationKey.PLACEHOLDER_RECOVERY_PASSWORD]: 'Электронная почта',
    [RecoveryPasswordTranslationKey.EMAIL_ERROR_TEXT]: 'Укажите действительную электронную почту',
    [RecoveryPasswordTranslationKey.RECOVERY_PASS_BUTTON_TEXT]: 'Восстановить',
    [RecoveryPasswordTranslationKey.WELCOME_TEXT]: 'Подтвердите аккаунт',
    [RecoveryPasswordTranslationKey.CHANGE_PASS_TEXT]: 'Создайте новый пароль для учетной записи',
    [RecoveryPasswordTranslationKey.BACK_BUTTON_TEXT]: 'Назад к входу',
    [RecoveryPasswordTranslationKey.RECOVERY_PASS_TEXT]:
      `Введите Email, который вы использовали
      при регистрации, мы вышлем вам ссылку для смены пароля`,
    [RecoveryPasswordTranslationKey.CONFIRM_MSG]:
      `Ссылка на сброс пароля была отправлена.
      Проверьте ваш почтовый ящик. Если вы не получили письмо, пожалуйста, проверьте спам.`,
    [RecoveryPasswordTranslationKey.BACK_TO_SIGN_IN]: 'Назад к входу',
    [RecoveryPasswordTranslationKey.REQUEST_ERROR_MSG]: 'Запрос не был отправлен',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_TITLE_TEXT]: 'Замена пароля авторизации',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_SUBTITLE_TEXT]: 'Введите новый пароль',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_PLACEHOLDER]: 'Пароль',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_BUTTON_TEXT]: 'Изменить',
    [RecoveryPasswordTranslationKey.NEW_PASSWORD_ERROR_TEXT]: 'Пароль не может быть пустым',
    [RecoveryPasswordTranslationKey.ANY_DIFFICULTY]: 'Возникли трудности?',
    [RecoveryPasswordTranslationKey.ANY_QUESTION]: 'Возник вопрос?',
    [RecoveryPasswordTranslationKey.CALL_ME]: 'Позвоните или напишите нам',
  },
  [AppTranslationKey.SUPPLIERS]: {
    backCaptionButton: 'Назад',
    saveCaptionButton: 'Сохранить',
    noDataTitle: 'У Вас нет поставщиков :(',
    noDataText: 'Нажмите кнопку "Добавить поставщика" для создания нового',
    addButtonTitle: 'Добавить поставщика',
    addSupplierTitle: 'Добавление поставщика',
    editSupplierTitle: 'Редактирование поставщика',
    fieldInfoLabel: 'Описание поставщика',
    fieldStatusLabel: 'Статус поставщика',
    fieldAddressLabel: 'Адрес поставщика',
    fieldNameLabel: 'Наименование поставщика',
    selectStatus: 'Выберите статус ...',
    createSupplierSuccessMessage: 'Поставщика успешно созданный!',
    editSupplierSuccessMessage: 'Поставщика успешно обновленный!',
    tableColumns: '#|Наименование|Статус|Адрес',
    requiredFormField: 'Это обязательное поле',
    itemsPerPageLabel: 'Элементов на странице',
  },
  [AppTranslationKey.COMMON]: {
    itemsPerPageLabel: 'Элементов на странице',
    maxPhoneLengthError: 'Максимальное количество символов - 15',
    feedbackBtnTitle: 'Поддержка',
    tgLogoTip: 'Telegram поддержка',
    feedback: 'Email поддержка',
    textAreaText: 'Осталось {{countSymbols}} символов',
    textAreaErrorMaxLength: 'Слишком много символов',
    showDetailsCaption: 'Раскрыть',
    hideDetailsCaption: 'Скрыть',
    viewButton: 'Просмотр',
    editButton: 'Редактировать',
    selectBtn: 'Изменить',
    saveBtn: 'Сохранить',
    closeBtn: 'Закрыть',
    cancelBtn: 'Отмена',
    removeBtn: 'Удалить',
    applyButton: 'Применить',
    permissionMessage: 'Чтобы продолжить пользоваться всеми возможностями системы, следует подключить тарифный план',
    itemPerPageLabel: 'Элементов на странице',
    uah: 'грн.',
    noItemsFoundTitle: 'Ничего не найдено',
    noItemsFoundMsg: 'Попытайтесь изменить условия поиска',
    docButtonCaption: 'Документация',
    inputNumberMaxValueWarning: 'Значение не может превышать {{maxValue}}',
    pcs: 'шт.',
    underConstructionMsg: 'Этот раздел в активной разработке',
    comingSoon: 'К сожалению, этот функционал пока находится в разработке. Спасибо за понимание!',
    required: 'Это обязательное поле',
  },
  [AppTranslationKey.STOCK]: {
    [StorageTranslationKey.ADD_STORAGE]: 'Добавить склад',
    [StorageTranslationKey.NO_DATA_TITLE]: 'У Вас нет ни одного Склада :(',
    [StorageTranslationKey.NO_DATA_TEXT]: 'Нажмите кнопку "Добавить склад", чтобы создать его',
    [StorageTranslationKey.ADD_STORAGE_TITLE]: 'Добавление Складу',
    [StorageTranslationKey.EDIT_STORAGE_TITLE]: 'Редактирование Складу',
    [StorageTranslationKey.STORAGE_FORM_FIELD_REQUIRED]: 'Это обязательное поле',
    [StorageTranslationKey.STORAGE_FORM_NAME_LABEL]: 'Наименование*',
    [StorageTranslationKey.STORAGE_FORM_STATUS_LABEL]: 'Статус',
    [StorageTranslationKey.STORAGE_FORM_ADDRESS_LABEL]: 'Адрес*',
    [StorageTranslationKey.STORAGE_FORM_TOO_MANY_CHAR]: 'Слишком много символов',
    [StorageTranslationKey.STORAGE_FORM_INFO_LABEL]: 'Описание',
    [StorageTranslationKey.STORAGE_FORM_INFO_TEXT]: 'Осталось {{countSymbols}} символов',
    [StorageTranslationKey.BACK_CAPTION_BUTTON]: 'Назад',
    [StorageTranslationKey.SAVE_CAPTION_BUTTON]: 'Сохранить',
    [StorageTranslationKey.ADD_STORAGE_SUCCESS]: 'Склад успешно добавлен!',
    [StorageTranslationKey.UPDATE_STORAGE_SUCCESS]: 'Склад успешно обновлен!',
  },
  [AppTranslationKey.STOCK_DETAILS]: {
    [StorageDetailsTranslationKey.STORAGE_DETAILS_NAME_FIELD]: 'Название',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_STATUS_FIELD]: 'Статус',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_ADDRESS_FIELD]: 'Адрес',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_INFO_FIELD]: 'Описание',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_EDIT_BUTTON]: 'Редактировать',
  },
  [AppTranslationKey.CAR_WORK]: {
    [CarWorkTranslationKey.ADD_CAR_WORK_CAPTION_BUTTON]: 'Редактировать избранные работы',
    [CarWorkTranslationKey.NO_CAR_WORK_TITLE]: 'У вас нет работ',
    [CarWorkTranslationKey.NO_CAR_WORK_TEXT]:
      'Нажмите кнопку "Редактировать выбранные работы", чтобы добавить/удалить работу из общего справочника робот',
    [CarWorkTranslationKey.CAR_WORK_TABLE_COLUMNS]:
      'Название работы|Тип работы|Время, мин|Стоимость|Информация',
    [CarWorkTranslationKey.FAV_CAR_WORK_NAME]: 'Название работы:',
    [CarWorkTranslationKey.UPDATE_SUCCESS]: 'Данные успешно обновлены',
    [CarWorkTranslationKey.FAV_CAR_WORK_TYPE]: 'Тип работы:',
    [CarWorkTranslationKey.FAV_CAR_WORK_TIME]: 'Время в минутах:',
    [CarWorkTranslationKey.FAV_CAR_WORK_COST]: 'Стоимость:',
    [CarWorkTranslationKey.FAV_CAR_WORK_INFO]: 'Информация:',
    [CarWorkTranslationKey.VALUE_LESS_ZERO]: 'Значение не может быть меньше 0',
    [CarWorkTranslationKey.INVALID_NUMBER]: 'Неверное значение',
    [CarWorkTranslationKey.ITEMS_PER_PAGE_LABEL]: 'Элементов на странице',
    [CarWorkTranslationKey.ALL_CAR_WORK_MODAL]: {
      [AllCarWorkModalTranslationKey.ALL_CAR_WORK_MODAL_TITLE]: 'Справочник сервисных работ',
      [AllCarWorkModalTranslationKey.CAR_WORK_MODAL_WORK_NAME]: 'Название работы',
      [AllCarWorkModalTranslationKey.CAR_WORK_MODAL_WORK_TYPE]: 'Тип работы',
      [AllCarWorkModalTranslationKey.CAR_WORK_NUM_MODAL]: 'Наряд',
      [AllCarWorkModalTranslationKey.CAR_WORK_PRICE_MODAL]: 'Цена',
      [AllCarWorkModalTranslationKey.CAR_WORK_QTY_MODAL]: 'Количество',
      [AllCarWorkModalTranslationKey.CAR_WORK_SUM_MODAL]: 'Сумма',
      [AllCarWorkModalTranslationKey.FEEDBACK_BUTTON_TITLE]: 'Не нашли нужной работы?',
    },
    [CarWorkTranslationKey.EDIT_CAR_WORK_MODAL_TITLE]: 'Редактирование работы',
    [CarWorkTranslationKey.SELECT_WORK_TO_ORDER_MODAL]: {
      [SelectWorkToOrderModalTranslationKey.TITLE]: 'Работы по заявке #',
      [SelectWorkToOrderModalTranslationKey.SELECTED]: 'Выбранные',
      [SelectWorkToOrderModalTranslationKey.SUM]: 'Сумма работ',
    },
  },
  [AppTranslationKey.SPARE_PARTS]: {
    addButtonTitle: 'Добавить запчасть',
    createSuccessMessage: 'Запчасть успешно создана!',
    editSuccessMessage: 'Запчасть успешно обновлена!',
    createModalTitle: 'Новая запчасть',
    editModalTitle: 'Редактирование запчасти',
    name: 'Наименование*',
    sparePartName: 'Название запчасти',
    orderId: 'Наряд',
    priceOne: 'Цена',
    count:'Количество',
    priceTotal:'Сумма',
    addInfo: 'Дополнительная информация',
    supplier: 'Поставщик*',
    valueCurrent: 'Количество на складе*',
    priceSell: 'Стоимость продажная*',
    valueMin: 'Минимальная стоимость*',
    originalCode: 'Оригинальный код',
    requiredFormField: 'Это обязательное поле',
    selectSupplier: 'Выберите поставщика ...',
    noDataTitle: 'У вас нет ни одной Запчасти :(',
    noDataText: 'Нажмите кнопку "Добавить запчасть", чтобы создать ее',
    tableColumns: '#|Наименование|Количество|Минимальное количество|Стоимость|Код',
    itemPerPageLabel: 'Элементов на странице',
    noStoreErrorMes: 'Для начала необходимо создать склад',
  },
  [AppTranslationKey.GDRP_MODAL]: {
    [GdrpTranslationKey.TITLE]: 'Политика конфиденциальности и обработки данных пользователей',
    [GdrpTranslationKey.CLOSE_BUTTON_CAPTION]: 'Закрыть',
  },
  [AppTranslationKey.WORK_ORDER]: {
    [WorkOrderTranslationKey.TITLE]: 'Наряды',
    [WorkOrderTranslationKey.SELECTED_ORDER_MSG] : '#{{orderId}} от {{date}} {{carInfo}}',
    [WorkOrderTranslationKey.CREATE_CANCELED_WORK_ORDER_ERROR_TEXT]: 'Невозможно создать наряд в статусе «Отменен»',
    [WorkOrderTranslationKey.EDIT_CANCELED_WORK_ORDER_ERROR_TEXT]: 'Редактирование отмененного наряда невозможно. ' +
    'Создайте новый наряд',
    [WorkOrderTranslationKey.ADD_WORK_ORDER]: 'Добавить наряд',
    [WorkOrderTranslationKey.WORK_ORDER_DETAILS_WORK_TITLE]: 'Работы',
    [WorkOrderTranslationKey.WORK_ORDER_DETAILS_SPARE_PARTS_TITLE]: 'Запчасти',
    [WorkOrderTranslationKey.WORK_ORDER_NO_WORK]: 'Наряд не имеет работ',
    [WorkOrderTranslationKey.WORK_ORDER_NO_SPARE_PARTS]: 'Наряд не имеет запчастей',
    [WorkOrderTranslationKey.NO_WORK_ORDERS_TITLE]: 'У вас нет нарядов',
    [WorkOrderTranslationKey.NO_WORK_ORDERS_TEXT]:
      'Нажмите кнопку "Добавить наряд", чтобы создать его',
    [WorkOrderTranslationKey.NUM_ORDER]: 'Заявка',
    [WorkOrderTranslationKey.DATE_TIME]: 'Дата и Время',
    [WorkOrderTranslationKey.AUTO]: 'Авто',
    [WorkOrderTranslationKey.COMMENT]: 'Комментарии',
    [WorkOrderTranslationKey.SUM_FULL]: 'Сумма',
    [WorkOrderTranslationKey.SUM_WORKS]: 'Сумма работ',
    [WorkOrderTranslationKey.SUM_SPARE_PARTS]: 'Сумма запчастей',
    [WorkOrderTranslationKey.STATUS]: 'Статус',
    [WorkOrderTranslationKey.ADD_SUCCESS]: 'Наряд добавлен',
    [WorkOrderTranslationKey.EDIT_SUCCESS]: 'Наряд успешно обновлен',
    [WorkOrderTranslationKey.TOTAL_WORK_ORDER_SUM]: 'Сумма наряду',
    [WorkOrderTranslationKey.WORK_ORDER_MODAL]: {
      [WorkOrderModalTranslationKey.TITLE]: 'Наряд',
      [WorkOrderModalTranslationKey.STATUS_LABEL]: 'Статус',
      [WorkOrderModalTranslationKey.ORDER]: 'Заявка*',
      [WorkOrderModalTranslationKey.DATE]: 'Дата',
      [WorkOrderModalTranslationKey.TIME]: 'Время',
      [WorkOrderModalTranslationKey.NO_DATA_MSG]: 'Нет данных',
      [WorkOrderModalTranslationKey.SELECT_BTN_CAPTION]: 'Выбрать',
      [WorkOrderModalTranslationKey.REQUIRED]: 'Это обязательное поле',
      [WorkOrderModalTranslationKey.WORK_NAME_TABLE_FIELD]: 'Название работы',
      [WorkOrderModalTranslationKey.SPARE_PART_NAME_TABLE_FIELD]: 'Название запчасти',
      [WorkOrderModalTranslationKey.QTY_TABLE_FIELD]: 'Количество',
      [WorkOrderModalTranslationKey.COST_TABLE_FIELD]: 'Цена',
      [WorkOrderModalTranslationKey.SUM_TABLE_FIELD]: 'Сумма',
      [WorkOrderModalTranslationKey.NO_BIND_ORDER_ERROR_MSG]: 'Необходимо выбрать заявку',
      [WorkOrderModalTranslationKey.EMPLOYEE]: 'Исполнитель',
    },
  },
  [AppTranslationKey.TARIFFS] :{
    title: 'Просмотр тарифов',
    testTariffName: 'Пробный безлимит',
    minTariffName: 'Минимум',
    standardTariffName: 'Стандарт',
    unlimitedTariffName: 'Безлимит',
    premiumTariffName: 'Премиум',
    countRequests: 'Количество открытых заявок',
    works: 'Работы',
    workOutfit: 'Наряд-заказ',
    provider: 'Поставщики',
    storage: 'Склад',
    calculateCost: 'Рассчитать стоимость тарифа',
    period: 'Срок подписки',
    month: 'мес',
    subscribeButton: 'Подключить',
    calculatePrice: 'Рассчитать стоимость',
    periodSubscribers: 'Срок подписки {{countMonth}} / мес',
    descriptionPayment: 'Использование UkrCarService. Тариф: {{tariffName}} - {{countMonth}} мес',
    currencyUAH: 'грн.',
    disableButtonText: 'Недоступно',
    activePanelText: 'Активный до {{endDate}}',
    planedPanelText: 'Запланированный с  {{startDate}}',
    finishedPanelText: 'Закончился {{endDate}}',
    calcPrice: 'Рассчитать стоимость',
    addInfo: {
      partnership: {
        info1: 'Срок действия – неограниченный при условии:',
        info2: 'активного использования ERP-системы',
        info3: 'предоставление отзывов и предложений по функционалу',
      },
      testUnlimited: {
        info1: 'Срок подписки – 1 мес',
        info2: 'Активируется при создании первого СТО',
        info3: 'Активируется при создании первого склада',
      },
    },
  },
  [AppTranslationKey.HELP] :{
    title: 'Видеообзор системы',
    video_title: 'Видеообзор на текущий момент',
  },
  [AppTranslationKey.SELECT_ORDER_MODAL]: {
    [SelectOrderModalTranslationKey.TITLE] : 'Заявки',
    [SelectOrderModalTranslationKey.NO_ORDER_SELECTED] : 'Заявка не выбрана',
    [SelectOrderModalTranslationKey.AUTO_TABLE_FIELD] : 'Авто',
    [SelectOrderModalTranslationKey.DATETIME_TABLE_FIELD] : 'Дата и Время',
    [SelectOrderModalTranslationKey.COMMENT_TABLE_FIELD] : 'Комментарий',
    [SelectOrderModalTranslationKey.STATUS_TABLE_FIELD] : 'Статус',
    [SelectOrderModalTranslationKey.ALERT_ORDER_TITLE] : 'Заявка:',
    [SelectOrderModalTranslationKey.ALERT_AUTO_TITLE] : 'Авто:',
    [SelectOrderModalTranslationKey.SELECTED_ORDER_MSG] : '#{{orderId}} от {{date}}',
  },
  [AppTranslationKey.SELECT_WORK_MODAL]: {
    [SelectWorkModalTranslationKey.NO_SELECT]: 'Нет выбранных работ',
    [SelectWorkModalTranslationKey.SELECTED]: 'Выбрано',
    [SelectWorkModalTranslationKey.SELECT_WORK_TITLE]: 'Выбрать работу',
    [SelectWorkModalTranslationKey.SELECT_WORK_SUM]: 'Сумма работ',
  },
  [AppTranslationKey.SELECT_SPARE_PARTS_MODAL]: {
    [SelectSparePartsModalTranslationKey.TITLE]: 'Запчасти',
    [SelectSparePartsModalTranslationKey.SELECTED]: 'Выбрано',
    [SelectSparePartsModalTranslationKey.NO_SELECT]: 'Нет выбранных запчастей',
  },
  [AppTranslationKey.SELECT_SPARE_PARTS_TABLE]: {
    [SelectSparePartsTableTranslationKey.NO_SPARE_PARTS_WORK_TITLE]: 'У вас нет запчастей',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_NAME]: 'Название',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_STOCK_NAME]: 'Склад',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_QTY]: 'Количество',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_PRICE]: 'Цена',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_SUM]: 'Сумма',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_COUNTERPARTY_NAME]: 'Поставщик',
    [SelectSparePartsTableTranslationKey.TOTAL_SPARE_PARTS_SUM]: 'Сумма',
  },
  [AppTranslationKey.DANGER_ALERT_MODAL]: {
    [DangerAlertModalTranslationKey.CANCEL_WORK_ORDER_TEXT]:
    'Вы уверены в отмене наряда? Все запчасти вернутся на склад, ' +
    'а наряд больше не будет доступен для редактирования.',
    [DangerAlertModalTranslationKey.CANCEL_BUTTON_TEXT]: 'Назад',
    [DangerAlertModalTranslationKey.CANCEL_WORK_ORDER_BUTTON_TEXT]: 'Отклонить наряд',
    [DangerAlertModalTranslationKey.DELETE_PHONE_TEXT]: 'Вы уверены, что хотите удалить телефон?',
    [DangerAlertModalTranslationKey.DELETE_PHONE_BUTTON_TEXT]: 'Удалить телефон',
    [DangerAlertModalTranslationKey.CANCEL_ORDER_TEXT]:
      'Вы уверены в отклонении заявки? Все наряды будут упразднены, а запчасти вернутся на склад.',
    [DangerAlertModalTranslationKey.CANCEL_ORDER_BUTTON_TEXT]: 'Отклонить заявку',
  },
  [AppTranslationKey.INFO_VIN]: {
    [InfoVinTranslationKey.TITLE]: 'Просмотр информации по VIN',
    [InfoVinTranslationKey.SEARCH_BUTTON_TEXT]: 'Поиск',
    [InfoVinTranslationKey.SEARCH_INPUT_TITLE]: 'Поиск информации об автомобиле',
    [InfoVinTranslationKey.VIN_INPUT_PLACEHOLDER]: 'Введите VIN',
    [InfoVinTranslationKey.GOS_NUMBER_INPUT_PLACEHOLDER]: 'Введите гос. номер',
    [InfoVinTranslationKey.GOS_NUMBER_INPUT_ACTIVE_PLACEHOLDER]: 'АА0000АА',
    [InfoVinTranslationKey.ERROR_SHORT_VIN]: 'VIN слишком короткий. Он должен содержать 17 символов',
    [InfoVinTranslationKey.ERROR_LONG_VIN]: 'VIN слишком длинный. Он должен содержать 17 символов',
    [InfoVinTranslationKey.ERROR_REG_NUM]: 'Гос. номер должен быть действительным',
    [InfoVinTranslationKey.ERROR_EMPTY_SEARCH]: 'Необходимо заполнить хотя бы одно из полей: ' +
      'Вин-код или государственный номер',
    [InfoVinTranslationKey.TABLE_INFO_TITLE]: 'Информация об автомобиле',
    [InfoVinTranslationKey.SEARCH_INFO_MESSAGE_TITLE]: 'Получите информацию об автомобиле с помощью VIN-кода ' +
    'или государственного номера просто и быстро!',
    [InfoVinTranslationKey.SEARCH_INFO_MESSAGE_TEXT]: '<p>Введите VIN-код или государственный номер ' +
    'транспортного средства в поле поиска выше, чтобы получить технические характеристики. ' +
    'Обратите внимание, что система предоставляет информацию по VIN-коду только для транспортных средств, ' +
    'зарегистрированных или перерегистрированных после 2019 года. ' +
    'Поиск по государственному номеру включает в себя гораздо больший объем автомобилей.</p>' +
    '<p><bold>Как правильно ввести VIN-код и государственный номер в поисковое поле?</bold> ' +
    'Наша система поиска обеспечивает легкость при вводе данных и быстроту обработки вашего запроса.</p>' +
    '<ul><li>VIN-код и Государственный номер можно вводить как большими, так и маленькими символами, ' +
    'с пробелами или без них. Если данные содержат тире "-", его необходимо вписывать.</li>' +
    '<li>Спорные буквы, такие как "і", "а", "о", "В", "К", “е”, и другие, можно вводить как кириллицей, ' +
    'так и на латинице.</li></ul>',
    [InfoVinTranslationKey.TABLE_FIELD_CAR]: 'Авто',
    [InfoVinTranslationKey.TABLE_FIELD_VIN]: 'VIN',
    [InfoVinTranslationKey.TABLE_FIELD_YEAR]: 'Год выпуска',
    [InfoVinTranslationKey.TABLE_FIELD_COLOR]: 'Цвет',
    [InfoVinTranslationKey.TABLE_FIELD_BODY]: 'Тип кузова',
    [InfoVinTranslationKey.TABLE_FIELD_FUEL]: 'Тип топлива',
    [InfoVinTranslationKey.TABLE_FIELD_CAPACITY]: 'Объем двигателя',
    [InfoVinTranslationKey.TABLE_FIELD_OWN_WEIGHT]: 'Собственный вес',
    [InfoVinTranslationKey.TABLE_FIELD_TOTAL_WEIGHT]: 'Общий вес',
    [InfoVinTranslationKey.TABLE_FIELD_REG_NUM]: 'Гос. номер',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_TITLE]: 'Что такое VIN-код?',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_TEXT]: '<0>VIN</0> (Vehicle Identification Number), также известен как ' +
    '“номер кузова” - является уникальной комбинацией символов, идентифицирующей автомобиль. ' +
    'Этот идентификационный номер назначается изготовителем с целью идентификации каждого отдельного ' +
    'транспортного средства.',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_CAR_TITLE]: 'Где находится VIN-код?',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_CAR_TEXT]: 'Помимо техпаспорта, VIN-код автомобиля можно ' +
    'найти на идентификационной табличке, которая обычно расположена в специальном окошечке у лобового стекла ' +
    'со стороны водителя, на стойке передней левой двери, под обшивкой полов рядом с сиденьями впереди ' +
    'или под капотом или арками колес.',
  },
  [AppTranslationKey.ERROR_PAGE]: {
    [ErrorPageTranslationKey.TITLE]: 'Что-то пошло не так...',
    [ErrorPageTranslationKey.BACK_TO_MAIN]: 'Вернуться на главную',
    [ErrorPageTranslationKey.CONTACT_US_TEXT]: 'Если ошибка повторяется, пожалуйста,',
    [ErrorPageTranslationKey.CONTACT_US_LINK_TEXT]: 'свяжитесь с нашей поддержкой.',
    [ErrorPageTranslationKey.MSG]:
    '<p>К сожалению, произошла ошибка при обработке вашего запроса. ' +
    'Мы уже работаем над решением этой проблемы. Пожалуйста, попробуйте снова позже.</p>' +
    '<p><bold>Рекомендуемые действия:</bold></p>' +
    '<ul><li>Перезагрузите страницу и повторите попытку.</li>' +
    '<li>Проверьте подключение к Интернету и попробуйте снова.</li>' +
    '</ul>' +
    '<p><bold>Дополнительная помощь:</bold></p>' +
    '<ul>' +
    '<li>Если проблема остается, ' +
    '<contactUs>свяжитесь с нашей поддержкой</contactUs> ' +
    'или позвоните нам по горячей линии 0 800 332 308.</li>' +
    '</ul>',
  },
  [AppTranslationKey.NOT_FOUND_PAGE]: {
    [NotFoundPageTranslationKey.TITLE]: 'К несчастью! Вы потерялись.',
    [NotFoundPageTranslationKey.MSG]: 'Страница, которую вы ищете, не найдена',
  },
  [AppTranslationKey.ORDER_CERTIFICATE]: {
    [OrderCertificateKey.PAGE_TITLE]: 'Просмотр акта выполненных работ',
    [OrderCertificateKey.TITLE]: 'Акт выполненных работ {{num}}',
    [OrderCertificateKey.PERFORMER_SIDE_TITLE]: 'Исполнитель',
    [OrderCertificateKey.CUSTOMER_SIDE_TITLE]: 'Заказчик',
    [OrderCertificateKey.NAME_SERVICE_STATION]: 'Название СТО',
    [OrderCertificateKey.ADDRESS_SERVICE_STATION]: 'Адрес',
    [OrderCertificateKey.PHONE_SERVICE_STATION]: 'Тел',
    [OrderCertificateKey.BRAND_CAR]: 'Марка',
    [OrderCertificateKey.MODEL_CAR]: 'Модель',
    [OrderCertificateKey.GOV_NUMBER_CAR]: 'Гос. номер',
    [OrderCertificateKey.COMPLETED_WORKS_TABLE_TITLE]: 'Выполненные работы',
    [OrderCertificateKey.NAME_WORKS]: 'Название работы',
    [OrderCertificateKey.QUANTITY]: 'Количество',
    [OrderCertificateKey.PRICE]: 'Цена',
    [OrderCertificateKey.SUM]: 'Сумма',
    [OrderCertificateKey.UNIT]: 'шт',
    [OrderCertificateKey.UAH]: 'грн',
    [OrderCertificateKey.TOTAL]: 'Итого',
    [OrderCertificateKey.SPARE_PARTS_TABLE_TITLE]: 'Использованные запчасти (материалы), оплаченные заказчиком',
    [OrderCertificateKey.NAME_SPARE_PARTS]: 'Название запчасти',
    [OrderCertificateKey.CODE]: 'Код',
    [OrderCertificateKey.SPARE_PARTS_CLIENT_TABLE_TITLE]: 'Использованные запчасти, принятые от заказчика',
    [OrderCertificateKey.TOTAL_TABLE_TITLE]: 'Общая стоимость',
    [OrderCertificateKey.TOTAL_PRICE_WORKS]: 'Стоимость работ',
    [OrderCertificateKey.TOTAL_PRICE_SPARE_PARTS]: 'Стоимость запчастей',
    [OrderCertificateKey.TOTAL_PRICE]: 'Всего до оплаты',
    [OrderCertificateKey.FOOTER_TITLE]: 'Замечания и рекомендации клиенту по обслуживанию/ремонту ' +
    'и эксплуатации ТС',
    [OrderCertificateKey.VEHICLE_CONFIRM]: 'Транспортное средство сдал',
    [OrderCertificateKey.CONDITIONS_CONFIRM]: 'С объемом и стоимостью работ согласен. Транспортное средство принял, ' +
    'претензий к комплектности ДЗ нет',
    [OrderCertificateKey.PERFORMER_SIGN_TITLE]: 'От исполнителя',
    [OrderCertificateKey.CUSTOMER_SIGN_TITLE]: 'От заказчика',
    [OrderCertificateKey.FULL_NAME]: 'Фамилия и имя',
    [OrderCertificateKey.SIGNATURE]: 'подпись',
    [OrderCertificateKey.DATE]: 'Дата',
    [OrderCertificateKey.DOWNLOAD_PDF]: 'Загрузка PDF',
    [OrderCertificateKey.BACK_BUTTON]: 'Назад',
  },
  [AppTranslationKey.CLIENT_SPARE_PARTS]: {
    [ClientSparePartsTranslationKey.NOT_ADDED_SPARE_PART_ERROR]: 'Пожалуйста, нажмите "Добавить" или очистите поля',
    [ClientSparePartsTranslationKey.TITLE]: 'Запчасти от заказчика',
    [ClientSparePartsTranslationKey.NAME]: 'Название',
    [ClientSparePartsTranslationKey.COUNT]: 'Количество',
    [ClientSparePartsTranslationKey.ADD_SPARE_PART_TITLE]: 'Добавить',
    [ClientSparePartsTranslationKey.ADDED]: 'Добавлено',
    [ClientSparePartsTranslationKey.NO_SPARE_PARTS]: 'Нет запчастей от заказчика',
    [ClientSparePartsTranslationKey.CLOSE]: 'Закрыть',
    [ClientSparePartsTranslationKey.SAVE]: 'Сохранить',
    [ClientSparePartsTranslationKey.REQUIRED]: 'Это обязательное поле',
    [ClientSparePartsTranslationKey.EMPTY_SPARE_PARTS_LIST_ERROR]:
      'Форма не может быть пустой. Добавьте хотя бы одну запчасть',
    [ClientSparePartsTranslationKey.SUCCESS_ADDED_SP]: 'Запчасти заявки успешно изменены.',
  },
  [AppTranslationKey.FEED_BACK_MODAL]: {
    [FeedBackModalKey.TITLE]: 'Форма отзывов, вопросов, предложений',
    [FeedBackModalKey.DEFAULT_PLACEHOLDER]: 'Содержимое сообщения...',
    [FeedBackModalKey.SEND_BUTTON_TITLE]: 'Отправить',
    [FeedBackModalKey.ERROR_MESSAGE]: 'Возникла ошибка при отправке письма',
    [FeedBackModalKey.SUCCESS_MESSAGE]: 'Спасибо за обращение! Ответ получите на ваш email: {{email}}',
    [FeedBackModalKey.WARNING_FILES_SIZE]: 'Общий размер файлов должен быть не более 25МБ.',
    [FeedBackModalKey.MB]: 'МБ',
    [FeedBackModalKey.KB]: 'КБ',
  },
  [AppTranslationKey.HELP_ABOUT_REQUEST]: {
    [HelpAboutRequestKey.TITLE]: 'При каких статусах заявка считается открытой?',
    [HelpAboutRequestKey.DESCRIPTION]: 'Система использует ряд статусов для отображения текущего этапа обслуживания ' +
    'автомобиля. Для пользователей, оформивших тарифный план с ограниченным количеством открытых заявок, необходимо ' +
    'знать, что статусы делятся на две категории:',
    [HelpAboutRequestKey.ACTIVE_STATUSES_TITLE]: '(Заявка считается открытой):',
    [HelpAboutRequestKey.NO_ACTIVE_STATUSES_TITLE]: '(Заявка считается неактивной на данный момент или закрытой):',
    [HelpAboutRequestKey.A_IN_WORK]: 'В работе;',
    [HelpAboutRequestKey.A_WAIT_SPARE_PARTS]: 'В ожидании запчастей;',
    [HelpAboutRequestKey.A_IN_WORK_CONTRACTOR]: 'В работе у подрядчика;',
    [HelpAboutRequestKey.A_WORKS_FINISHED]: 'Работы завершены;',
    [HelpAboutRequestKey.A_WAIT_PAY]: 'В ожидании оплаты;',
    [HelpAboutRequestKey.A_WAIT_GIVE_CAR]: 'В ожидании выдачи автомобиля.',
    [HelpAboutRequestKey.NA_NEW]: 'Новая;',
    [HelpAboutRequestKey.NA_WAIT_AGREEMENT]: 'На согласовании;',
    [HelpAboutRequestKey.NA_AGREEMENT]: 'Согласовано;',
    [HelpAboutRequestKey.NA_WAIT_RECEIVE_CAR]: 'В ожидании приема автомобиля;',
    [HelpAboutRequestKey.NA_WAIT_WORK]: 'ВВ ожидании принятия в работу;',
    [HelpAboutRequestKey.NA_CAR_GAVE]: 'Автомобиль выдан;',
    [HelpAboutRequestKey.NA_CLOSE]: 'Закрыта;',
    [HelpAboutRequestKey.NA_CANCEL]: 'Отклонена;',
  },
  [AppTranslationKey.EMPLOYEES_PROFILES]: {
    [EmployeesProfilesKey.TITLE]: 'Профили сотрудников',
    [EmployeesProfilesKey.ADD_BUTTON_TITLE]: 'Добавить сотрудника',
    [EmployeesProfilesKey.NO_UNIT_TITLE]: 'У Вас нет сотрудников :(',
    [EmployeesProfilesKey.NO_UNIT_TEXT]: 'Нажмите кнопку "Добавить сотрудника" чтобы создать его',
    [EmployeesProfilesKey.MODAL_TITLE]: 'Сотрудник',
    [EmployeesProfilesKey.FIELDS]: {
      [EmployeesProfilesKey.FIRST_NAME]: 'Имя',
      [EmployeesProfilesKey.SECOND_NAME]: 'Фамилия',
      [EmployeesProfilesKey.PATRONYMIC_NAME]: 'Отчество',
      [EmployeesProfilesKey.POSITION]: 'Должность',
      [EmployeesProfilesKey.REGISTRATION_DATE]: 'Дата принятия на работу',
      [EmployeesProfilesKey.STATUS]: 'Статус',
      [EmployeesProfilesKey.DISMISSAL_DATE]: 'Дата увольнения',
      [EmployeesProfilesKey.METHOD_OF_SALARY]: 'Способ начисления заработной платы',
      [EmployeesProfilesKey.BIRTHDATE]: 'День рождения',
      [EmployeesProfilesKey.PHONE]: 'Телефон',
      [EmployeesProfilesKey.VIBER]: 'Viber',
      [EmployeesProfilesKey.TELEGRAM]: 'Telegram',
      [EmployeesProfilesKey.EMAIL]: 'Email',
      [EmployeesProfilesKey.ADD_INFO]: 'Дополнительная информация',
      [EmployeesProfilesKey.PERCENT_WORKS]: 'Размер процента от стоимости выполненных работ',
      [EmployeesProfilesKey.PERCENT_SPARES]: 'Процент от продаж запчастей',
      [EmployeesProfilesKey.HOURLY_RATE]: 'Повременная оплата на основе запланированных нормо-часов',
      [EmployeesProfilesKey.FIXED_RATE]: 'Размер фиксированной ежемесячной ставки',
      [EmployeesProfilesKey.INVALID_PHONE_NUMBER]: 'Недействительный номер телефону',
      [EmployeesProfilesKey.EMAIL_ERROR_TEXT]: 'Укажите действительную электронную почту',
      [EmployeesProfilesKey.PERCENT_WORKS_V2]: 'Процент от стоимости выполненных работ',
      [EmployeesProfilesKey.PERCENT_SPARES_V2]: 'Процент от продаж запчастей',
      [EmployeesProfilesKey.HOURLY_RATE_V2]: 'Повременная оплата на основе запланированных ' +
        'нормо-часов для каждой работы',
      [EmployeesProfilesKey.FIXED_RATE_V2]: 'Фиксированная ежемесячная ставка',
      [EmployeesProfilesKey.CONTACTS]: 'Контакты',
      [EmployeesProfilesKey.WORK_PERIOD]: 'Период работы',
    },
    [EmployeesProfilesKey.COLUMNS]: {
      [EmployeesProfilesKey.FULL_NAME]: 'ФИО',
      [EmployeesProfilesKey.ADD_INFO]: 'Дополнительная информация',
      [EmployeesProfilesKey.POSITION]: 'Должность',
      [EmployeesProfilesKey.PHONE]: 'Телефон',
      [EmployeesProfilesKey.STATUS]: 'Статус',
    },
    [EmployeesProfilesKey.REQUIRED_FORM_FIELD]: 'Это обязательное поле',
    [EmployeesProfilesKey.SUCCESS_UPDATE_MESSAGE]: 'Сотрудник успешно обновлен',
    [EmployeesProfilesKey.SUCCESS_CREATE_MESSAGE]: 'Сотрудник успешно добавлен!',
    [EmployeesProfilesKey.DISMISSAL_DATE_REQUIRED]: 'Необходимо указать дату увольнения',
    [EmployeesProfilesKey.DISMISSAL_STATUS_REQUIRED]: 'Необходимо установить статус: уволен',
  },
  [AppTranslationKey.SELECT_COMPONENT]: {
    [SelectComponentKey.SEARCH_SELECT_PLACEHOLDER]: 'Начните писать для выбора ...',
    [SelectComponentKey.OTHER_OPTION_NAME]: 'Другое...',
  },
  [AppTranslationKey.SALARY]: {
    [SalaryKey.TITLE]: ' Зарплата',
    [SalaryKey.COLUMNS]: {
      [SalaryKey.FULL_NAME]: 'ФИО',
      [SalaryKey.POSITION]: 'Должность',
      [SalaryKey.RATE]: 'Ставка',
      [SalaryKey.NORMA_HOURS]: 'Нормо-часы',
      [SalaryKey.PERCENT_OF_WORK]: '% от работ',
      [SalaryKey.PERCENT_OF_SPARE_PARTS]: '% от запчастей',
      [SalaryKey.PRIZE]: 'Премия',
      [SalaryKey.PENALTIES]: 'Штрафы',
      [SalaryKey.TOGETHER]: 'Итого',
      [SalaryKey.TOTAL]: 'Всего',
      [SalaryKey.TOTAL_EMPLOYEES]: 'Всего сотрудников',
      [SalaryKey.FIN_INFO_CELL_TOOLTIP]: 'Информация по сотруднику #{{id}}',
    },
  },
  [AppTranslationKey.EMPLOYEE_FIN_INFO]: {
    [EmployeeFinInfoKey.TITLE]: 'Информация по сотруднику #{{id}}',
    [EmployeeFinInfoKey.POSITION]: 'Должность',
    [EmployeeFinInfoKey.METHOD_CALC_SALARY]: 'Способ начисления ЗП',
    [EmployeeFinInfoKey.TOTAL_WORK_HOURS]: 'Всего рабочих часов',
    [EmployeeFinInfoKey.TOTAL_DONE_ORDERS]: 'Всего выполнено наряд-заказов',
    [EmployeeFinInfoKey.TOTAL_DONE_WORKS]: 'Всего выполнено работ',
    [EmployeeFinInfoKey.TOTAL_NORM_HOURS]: 'Всего нормо-часов',
    [EmployeeFinInfoKey.SALARY]: 'Зарплата',
    [EmployeeFinInfoKey.SALARY_ACCRUED]: 'Начислено ЗП',
    [EmployeeFinInfoKey.BONUS]: 'Премия',
    [EmployeeFinInfoKey.FINES]: 'Штрафы',
    [EmployeeFinInfoKey.TOTAL]: 'Всего',
    [EmployeeFinInfoKey.PAID_OUT]: 'Выплачено',
    [EmployeeFinInfoKey.FOR_PAYMENT]: 'К оплате',
    [EmployeeFinInfoKey.FINE]: 'Штраф',
    [EmployeeFinInfoKey.PAY_SALARY]: 'Выплатить ЗП',
    [EmployeeFinInfoKey.FILTERS]: {
      [EmployeeFinInfoKey.LAST_MONTH]: 'Прошлый месяц',
      [EmployeeFinInfoKey.LAST_WEEK]: 'Прошлая неделя',
      [EmployeeFinInfoKey.TODAY]: 'Сегодня',
      [EmployeeFinInfoKey.CURRENT_WEEK]: 'Текущая неделя',
      [EmployeeFinInfoKey.CURRENT_MONTH]: 'Текущий месяц',
    },
    [EmployeeFinInfoKey.TOOLTIP_PROFILE_USER]: 'Просмотреть профиль сотрудника',
  },
  [AppTranslationKey.CASH_DESK]: {
    [CashDeskKey.TITLE]: 'Касса',
    [CashDeskKey.ADD_CASH_DESK_BUTTON_TITLE]: 'Добавить ордер',
    [CashDeskKey.NO_CASH_ORDERS_TITLE]: 'У вас нет ордеров :(',
    [CashDeskKey.NO_CASH_ORDERS_TEXT]: 'Нажмите кнопку "Добавить ордер" чтобы создать его',
    [CashDeskKey.SUCCESS_ADDED]: 'Ордер успешно добавлен',
    [CashDeskKey.SUCCESS_UPDATED]: 'Ордер успешно обновлен',
    [CashDeskKey.MODAL]: {
      [CashDeskModalKey.TITLE]: 'Ордер',
      [CashDeskModalKey.DATE]: 'Дата',
      [CashDeskModalKey.TIME]: 'Время',
      [CashDeskModalKey.CASH_DESK_TYPE_LABEL]: 'Тип ордера',
      [CashDeskModalKey.CASH_DESK_CALC_METHOD_LABEL]: 'Способ расчета',
      [CashDeskModalKey.CASH_DESK_SUM_LABEL]: 'Сумма',
      [CashDeskModalKey.CASH_DESK_ASSIGNMENT]: 'Назначение ордера',
      [CashDeskModalKey.CASH_DESK_INFO]: 'Дополнительная информация',
    },
    [CashDeskKey.CASH_ORDERS_TABLE]: {
      [CashOrdersTableKey.DATE_TIME]: 'Дата и Время',
      [CashOrdersTableKey.ORDER_TYPE]: 'Тип ордера',
      [CashOrdersTableKey.ORDER_CALC_METHOD]: 'Способ расчета',
      [CashOrdersTableKey.SUM]: 'Сумма',
      [CashOrdersTableKey.ASSIGNMENT]: 'Назначение ордера',
      [CashOrdersTableKey.ORDER]: 'Заявка',
      [CashOrdersTableKey.INFO]: 'Дополнительная информация',
    },
  },
};
