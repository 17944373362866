import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';
import { AppTranslationKey, EmployeeFinInfoKey, LocalStorageKey } from 'common/enums/enums';
import { FiltersData } from 'common/types/types';
import { Page404 } from 'components/page-404/page-404';
import { FilterContextProvider } from 'contexts';
import { useParams , useSearchParams } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { filterInitialData } from './common';
import { FilterPanel } from './components/filter-panel';
import { Widgets } from './components/widgets';

const FinInfoEmployee: FC = () => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { employeeId } = useParams();
  const [urlParams] = useSearchParams();
  const carServiceId = urlParams.get('carServiceId');

  if (!carServiceId || !employeeId) {
    return <Page404 />;
  }

  return (
    <CCard>
      <CCardHeader>
        <h5 className="text-dark">{t(EmployeeFinInfoKey.TITLE, { id: employeeId })}</h5>
      </CCardHeader>
      <FilterContextProvider<FiltersData>
        storageKey={ `${ carServiceId }_${ employeeId }` }
        storageName={ LocalStorageKey.EMPLOYEE_FIN_INFO_FILTERS }
        initialValue={ filterInitialData }
      >
        <FilterPanel employeeId={ +employeeId } carServiceId={ +carServiceId } />
        <CCardBody style={{ overflowX: 'auto' }}>
          <Widgets employeeId={ +employeeId } carServiceId={ +carServiceId } />
        </CCardBody>
      </FilterContextProvider>
    </CCard>
  );
};

export { FinInfoEmployee };
