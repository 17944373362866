import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey, CashDeskKey, CashDeskModalKey } from 'common/enums/enums';
import { TextArea } from 'components/textarea/text-area';
import React, { FC } from 'react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { CashDeskFormData } from '../type';

type Props = {
  register: UseFormRegister<CashDeskFormData>,
  disabled: boolean,
  watch: UseFormWatch<CashDeskFormData>
};

const InfoCashDeskRow: FC<Props> = ({ register, disabled, watch }) => {

  const { t } = useTranslation(AppTranslationKey.CASH_DESK);
  const watchInfo = watch('cashDeskInfo');

  return (
    <CRow>
      <CFormLabel htmlFor="cashDeskInfo" className="col-sm-2 col-form-label">
        {t(`${CashDeskKey.MODAL}.${CashDeskModalKey.CASH_DESK_INFO}`)}
      </CFormLabel>
      <CCol sm={10}>
        <TextArea<CashDeskFormData>
          id="cashDeskInfo"
          register={ register }
          limitSymbols={ 1024 }
          rows={5}
          nameFiled="cashDeskInfo"
          value={ watchInfo }
          disabled={ disabled }
        />
      </CCol>
    </CRow>
  );
};

export { InfoCashDeskRow };
