import { CButton, CCard, CCardBody, CListGroup, CListGroupItem, CSmartTable } from '@coreui/react-pro';
import { Item } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey } from 'common/enums/enums';
import { usePermissionToastContext } from 'components/common/common';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

type Props = {
  carBodyTypeName: string,
  createYear: number,
  mileageKm: string,
  carInfo: string,
  capacity: number | null,
  fuelType: string,
  onViewEditOrderClick: (readOnly: boolean) => void;
};

const CarDetails: FC<Props> = (
  {
    carBodyTypeName,
    createYear,
    mileageKm,
    carInfo,
    onViewEditOrderClick,
    capacity,
    fuelType,
  }) => {
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE_AUTO);
  const { onPermissionAction } = usePermissionToastContext();

  return (
    <CCard color="light">
      <CCardBody>
        <CListGroup>
          <CListGroupItem style={{ display: 'flex' }}>
            <p>{t('carBodyTypeName')}:</p>
            <h5 className="ms-3">{carBodyTypeName}</h5>
          </CListGroupItem>
          <CListGroupItem style={{ display: 'flex' }}>
            <p>{t('createYear')}:</p>
            <h5 className="ms-3">{createYear}</h5>
          </CListGroupItem>
          <CListGroupItem style={{ display: 'flex' }}>
            <p>{t('mileageKm')}:</p>
            <h5 className="ms-3">{mileageKm}</h5>
          </CListGroupItem>
          <CListGroupItem style={{ display: 'flex' }}>
            <p>{t('capacity')}:</p>
            <h5 className="ms-3">{capacity}</h5>
          </CListGroupItem>
          <CListGroupItem style={{ display: 'flex' }}>
            <p>{t('fuelType')}:</p>
            <h5 className="ms-3">{fuelType}</h5>
          </CListGroupItem>
          {carInfo && <CListGroupItem>
            <div className={styles.carInfo}>
              <CSmartTable
                columns={[
                  {
                    key: 'carInfo',
                    label: `${t('carInfo')}:` ?? 'Info',
                    filter: false,
                    sorter: false,
                  },
                ]}
                items={[{
                  carInfo: carInfo,
                }]}
                scopedColumns={{
                  carInfo: (item: Item) => (
                    <td style={{ maxWidth: 0 }}>
                      {item.carInfo}
                    </td>
                  ),
                }}
              />
            </div>
            <p></p>
          </CListGroupItem>}
          <CListGroupItem color="info" className="d-flex justify-content-end align-items-center">
            <CButton
              variant="outline"
              className={styles.viewCarButton}
              onClick={(): void => onViewEditOrderClick(true)}
            >
              {t('carViewButton')}
            </CButton>
            <CButton
              variant="outline"
              color="danger"
              className={styles.editCarButton}
              onClick={onPermissionAction((): void => onViewEditOrderClick(false))}
            >
              {t('carEditButton')}
            </CButton>
          </CListGroupItem>
        </CListGroup>
      </CCardBody>
    </CCard>
  );
};

export { CarDetails };
