import { EmployeeSalary } from 'common/types/types';

const PERCENT_WORKS_ID = 1;
const PERCENT_SPARES_ID = 2;
const HOURLY_RATE_ID = 3;
const FIXED_RATE_ID = 4;

type TReturnValue = EmployeeSalary | undefined;

const getSalary = (salaryDataList: EmployeeSalary[], typeId: number): TReturnValue => {
  return (salaryDataList || []).find((item) => item.salaryType.salaryTypeId === typeId);
};

const getPercentWorks = (salaryDataList: EmployeeSalary[]): TReturnValue => getSalary(salaryDataList, PERCENT_WORKS_ID);
const getPercentSpares = (salaryDataList: EmployeeSalary[]): TReturnValue => getSalary(
  salaryDataList,
  PERCENT_SPARES_ID,
);
const getHourlyRate = (salaryDataList: EmployeeSalary[]):TReturnValue => getSalary(salaryDataList, HOURLY_RATE_ID);
const getFixedRate = (salaryDataList: EmployeeSalary[]): TReturnValue => getSalary(salaryDataList, FIXED_RATE_ID);

export {
  getFixedRate,
  getHourlyRate,
  getPercentSpares,
  getPercentWorks,
};
