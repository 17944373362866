import { TAnyValue } from 'common/types/types';
import { IUseFilter, useFilter } from 'hooks/hooks';
import { createContext, ReactNode, useContext } from 'react';

type FilterContextType = ReturnType<typeof useFilter>;

const FilterContext = createContext<FilterContextType | null>(null);

const useFilterContext = (): FilterContextType => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilterContext must be used within the FilterContextProvider');
  }

  return context;
};

interface IProps<T> extends IUseFilter<T> {
  children: ReactNode,
}

const FilterContextProvider = <T extends Record<string, TAnyValue>>({
  children,
  ...otherProps
}: IProps<T>): JSX.Element => (
    <FilterContext.Provider value={ useFilter(otherProps) }>
      { children }
    </FilterContext.Provider>
  );

export { FilterContextProvider, useFilterContext };
