import { cilClock } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import cn from 'classnames';
import { AppTranslationKey, EmployeeFinInfoKey } from 'common/enums/enums';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const NormaHoursWidget: FC = () => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);

  return (
    <div className={ styles.smallWidget }>
      <div className={ styles.title }>
        { t(EmployeeFinInfoKey.TOTAL_NORM_HOURS) }
      </div>
      <div className={ styles.description }>
        <span>
          <CIcon icon={ cilClock } className={ cn(styles.icon, 'icon-custom-size') } />
        </span>
        <span>--:--</span>
      </div>
    </div>
  );
};

export { NormaHoursWidget };
