import { ModalFormMode,ModalFormType } from 'common/enums/enums';
import { CarServiceDto } from 'common/types/types';
import { CashDeskModal } from 'components/modals/modals';
import { useAppSelector, useState } from 'hooks/hooks';
import { ReactElement } from 'react';

type UseCashDeskModal = {
  getCashDeskModal: () => ReactElement,
  toggleCashDeskModal: (isVisible: boolean) => void,
};

const useCashDeskModal = (activeCarService: CarServiceDto | null): UseCashDeskModal => {

  const {
    modalFormMode,
  } = useAppSelector( ({ modal })=> ({
    modalFormMode: modal.modalFormMode?.[ModalFormType.CASH_ORDER_MODAL] ?? ModalFormMode.READONLY_MODE,
  }));

  const [ isOpen, setOpen ] = useState<boolean>(false);

  const isReadOnly = modalFormMode === ModalFormMode.READONLY_MODE;

  const toggleCashDeskModal = (isVisible: boolean): void => setOpen(isVisible);

  const getCashDeskModal = (): ReactElement => {

    return <CashDeskModal
      carServiceId={activeCarService?.id ?? null}
      isReadOnly={isReadOnly}
      isOpen={ isOpen }
      onCloseModal={ (): void => toggleCashDeskModal(false) }
    />;
  };

  return { getCashDeskModal, toggleCashDeskModal };
};

export { useCashDeskModal };
