import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppTranslationKey , EmployeesProfilesKey } from 'common/enums/enums';
import {
  AsyncThunkConfig,
  EmployeeCreateDto,
  EmployeeGetItemPayload,
  EmployeeGetItemsPayload,
  EmployeeGetItemsResponse,
  EmployeeItem,
  EmployeeUpdateDto,
} from 'common/types/types';
import { t } from 'i18next';

import { ActionType } from './common';

const getEmployees = createAsyncThunk<
  EmployeeGetItemsResponse,
  EmployeeGetItemsPayload,
  AsyncThunkConfig
>(ActionType.GET_EMPLOYEES, async (payload, { extra }) => {
  const { employeesApi } = extra;

  return employeesApi.getEmployeesByCarServiceId(payload);
});

const getEmployee = createAsyncThunk<
  EmployeeItem,
  EmployeeGetItemPayload,
  AsyncThunkConfig
>(ActionType.GET_EMPLOYEE_BY_ID, async (payload, { extra }) => {
  const { employeesApi } = extra;

  return employeesApi.getEmployeesById(payload);
});

const createEmployee = createAsyncThunk<
  EmployeeItem,
  EmployeeCreateDto,
  AsyncThunkConfig
>(ActionType.CREATE_EMPLOYEE, async (payload, { extra, dispatch }) => {
  const { employeesApi, notification } = extra;
  const newEmployee = await employeesApi.createEmployee(payload);

  notification.success(t(`${AppTranslationKey.EMPLOYEES_PROFILES}:${EmployeesProfilesKey.SUCCESS_CREATE_MESSAGE}`) as string);

  dispatch(getEmployees({ carServiceId: payload.carServiceId }));

  return newEmployee;
});

const updateEmployee = createAsyncThunk<
  EmployeeItem,
  EmployeeUpdateDto,
  AsyncThunkConfig
>(ActionType.UPDATE_EMPLOYEE, async (payload, { extra }) => {
  const { employeesApi, notification } = extra;

  const updatedEmployee = await employeesApi.updateEmployee(payload);

  notification.success(t(`${AppTranslationKey.EMPLOYEES_PROFILES}:${EmployeesProfilesKey.SUCCESS_UPDATE_MESSAGE}`) as string);

  return updatedEmployee;
});

export { createEmployee, getEmployee, getEmployees, updateEmployee };
