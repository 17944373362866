import { cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import cn from 'classnames';
import { AppTranslationKey, EmployeeFinInfoKey } from 'common/enums/enums';
import { EmployeeModal } from 'components/modals/employee-modal/employee-modal';
import { getFixedRate, getFullName, getHourlyRate, getPercentSpares, getPercentWorks } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useMemo , useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { employeesActions } from 'store/actions';

import styles from './styles.module.scss';

interface CommonInfoWidgetProps {
  carServiceId: number,
  salaryDateFrom: string,
  salaryDateTo: string,
}

const CommonInfoWidget: FC<CommonInfoWidgetProps> = ({ carServiceId, salaryDateFrom, salaryDateTo }) => {
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isDisableModal, setIsDisableModal] = useState<boolean>(false);
  const { t } = useTranslation(AppTranslationKey.EMPLOYEE_FIN_INFO);
  const { t: tCommon } = useTranslation(AppTranslationKey.COMMON);
  const employee = useAppSelector(({ employees }) => employees.currentEmployee);
  const fullName = useMemo(
    () => getFullName(employee?.firstName, employee?.patronymicName, employee?.secondName),
    [employee?.firstName, employee?.patronymicName, employee?.secondName],
  );
  const percentWorks = useMemo(
    () => getPercentWorks(employee?.employeeSalaryDataList || []),
    [employee?.employeeSalaryDataList],
  );
  const percentSpares = useMemo(
    () => getPercentSpares(employee?.employeeSalaryDataList || []),
    [employee?.employeeSalaryDataList],
  );
  const horlyRate = useMemo(
    () => getHourlyRate(employee?.employeeSalaryDataList || []),
    [employee?.employeeSalaryDataList],
  );
  const fixedRate = useMemo(
    () => getFixedRate(employee?.employeeSalaryDataList || []),
    [employee?.employeeSalaryDataList],
  );

  const onOpenViewModal = (): void => {
    setIsOpenModal(true);
    setIsDisableModal(true);
  };

  const getEmployee = (): void => {
    dispatch(employeesActions.getEmployee({
      employeeId: employee?.employeeId || -1,
      carServiceId,
      salaryDateFrom,
      salaryDateTo,
    }));
  };

  return (
    <div className={ styles.commonInfoWidget }>
      <div className={ styles.fullName }>
        <CIcon icon={cilUser} className={ styles.icon }/>
        <div
          className={ styles.value }
          data-tooltip-id="fullNameUser"
          onClick={ onOpenViewModal }
        >
          { fullName }
        </div>
        <Tooltip
          id="fullNameUser"
          place="bottom-start"
          content={ t(EmployeeFinInfoKey.TOOLTIP_PROFILE_USER) as string }
        />
      </div>
      <div className={ styles.position }>
        <span>{ `${ t(EmployeeFinInfoKey.POSITION) }:` }</span>
        <span>{ employee?.employeePosition.employeePositionName || '' }</span>
      </div>
      <div>
        { `${ t(EmployeeFinInfoKey.METHOD_CALC_SALARY) }:` }
      </div>
      <div className={ styles.methodsSalary }>
        <div className={ cn(styles.methodSalaryItem, styles.isPercent) }>
          <span className={ styles.methodSalaryLabel }>
            { `${ percentWorks?.salaryType.salaryTypeDescription || '' }:` }
          </span>
          <span className={ styles.methodSalaryValue }>
            { `${ percentWorks?.salaryTypeValue || 0 }%` }
          </span>
        </div>
        <div className={ cn(styles.methodSalaryItem, styles.isPercent) }>
          <span className={ styles.methodSalaryLabel }>
            { `${ percentSpares?.salaryType.salaryTypeDescription || '' }:` }
          </span>
          <span className={ styles.methodSalaryValue }>
            { `${ percentSpares?.salaryTypeValue || 0 }%` }
          </span>
        </div>
        <div className={ styles.methodSalaryItem }>
          <span className={ styles.methodSalaryLabel }>
            { `${ horlyRate?.salaryType.salaryTypeDescription || '' }:` }
          </span>
          <span className={ styles.methodSalaryValue }>
            { `${ horlyRate?.salaryTypeValue || 0 } ${ tCommon('uah') }` }
          </span>
        </div>
        <div className={ styles.methodSalaryItem }>
          <span className={ styles.methodSalaryLabel }>
            { `${ fixedRate?.salaryType.salaryTypeDescription || '' }:` }
          </span>
          <span className={ styles.methodSalaryValue }>
            { `${ fixedRate?.salaryTypeValue || 0 } ${ tCommon('uah') }` }
          </span>
        </div>
      </div>
      {
        isOpenModal && (
          <EmployeeModal
            isOpen={ true }
            carServiceId={ carServiceId }
            employeeId={ employee?.employeeId }
            isDisable={ isDisableModal }
            onEnable={ (): void => setIsDisableModal(false) }
            onClose={ (): void => setIsOpenModal(false) }
            onAfterSubmit={ getEmployee }
          />
        )
      }
    </div>
  );
};

export { CommonInfoWidget };
