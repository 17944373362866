import { CButton, CCol, CFormInput, CFormLabel } from '@coreui/react-pro';
import { AppTranslationKey, CashDeskKey, CashDeskModalKey } from 'common/enums/enums';
import { onlyNumberString } from 'helpers/helpers';
import { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormClearErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { CashDeskFormData } from '../type';

type Props = {
  disabled: boolean,
  register: UseFormRegister<CashDeskFormData>,
  setValue: UseFormSetValue<CashDeskFormData>,
  errors: FieldErrors<CashDeskFormData>,
  clearErrors: UseFormClearErrors<CashDeskFormData>,
};

const SumCashDeskRow: FC<Props> = ({ register, disabled, setValue, clearErrors, errors }) => {
  const { t } = useTranslation([AppTranslationKey.CASH_DESK, AppTranslationKey.COMMON]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', paddingTop: 4 }}>
      <CCol sm={2}>
        <CFormLabel>
          {t(`${CashDeskKey.MODAL}.${CashDeskModalKey.CASH_DESK_SUM_LABEL}`)}
        </CFormLabel>
      </CCol>
      <CCol sm={9} className="ps-1">
        <CFormInput
          type="text"
          disabled={disabled}
          {...register('cashDeskSum', {
            required:
              t('required', { ns: AppTranslationKey.COMMON }) ??
              'This field is required',
          })}
          id="cashDeskSum"
          invalid={Boolean(errors.cashDeskSum)}
          feedbackInvalid={errors.cashDeskSum?.message}
          onChange={(event): void => {
            clearErrors('cashDeskSum');
            setValue('cashDeskSum', onlyNumberString(event.target.value));
          }}
        ></CFormInput>
      </CCol>
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <CButton color="secondary" disabled>
          {t('uah', { ns: AppTranslationKey.COMMON })}
        </CButton>
      </div>
    </div>
  );
};

export {  SumCashDeskRow };
