import { CFormInput } from '@coreui/react-pro';
import { onlyPhoneString } from 'helpers/helpers';
import { useState } from 'hooks/hooks';
import React, { forwardRef } from 'react';

type Props = {
  disabled: boolean,
  name: string,
  onChange: (valid: string) => void,
  feedbackInvalid: string,
  label?: string,
  placeholder?: string,
};

const PhoneInputValid = forwardRef<HTMLInputElement, Props>(({
  disabled,
  name,
  onChange,
  feedbackInvalid,
  label = '',
  placeholder = '',
  ...inputProps
}, ref) => {

  const [isPhoneInvalid, setPhoneInvalid] = useState<boolean>(false);

  const setMainPhoneValidValue = (value: string): void => {
    if (value.length > 15) {
      setPhoneInvalid(true);
      value = value.slice(0, 15);
    } else {
      setPhoneInvalid(false);
      value = onlyPhoneString(value);
    }

    onChange(value);
  };

  return <CFormInput
    ref={ref}
    {...inputProps}
    name={name}
    disabled={disabled}
    type="text"
    label={label}
    invalid={isPhoneInvalid}
    placeholder={placeholder}
    feedbackInvalid={feedbackInvalid}
    onChange={(event): void => setMainPhoneValidValue(event.target.value)}
    onBlur={(): void => setPhoneInvalid(false)}
  />;
});
export { PhoneInputValid };
