import { CCol, CFormLabel, CRow, CTimePicker } from '@coreui/react-pro';
import {
  AppTranslationKey,
  CashDeskKey, CashDeskModalKey,
} from 'common/enums/enums';
import { onShowTime } from 'helpers/helpers';
import React, { FC, ReactElement } from 'react';
import DatePicker from 'react-datepicker';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CashDeskFormData } from '../type';

type Props = {
  isReadOnly: boolean,
  control:  Control<CashDeskFormData> | undefined,
};

const DatetimeCashDeskRow: FC<Props> = ({ isReadOnly, control }) => {
  const { t, i18n } = useTranslation(AppTranslationKey.CASH_DESK);

  return (
    <CRow className="mb-3">
      <CFormLabel className="col-sm-2 col-form-label">
        {t(`${CashDeskKey.MODAL}.${CashDeskModalKey.DATE}`)}
      </CFormLabel>
      <CCol className="react-calendar-wrapper">
        <Controller
          name="cashDeskDate"
          render={({ field }): ReactElement => (
            <DatePicker
              disabled={isReadOnly}
              id="cashDeskDate"
              selected={field.value}
              locale={i18n.language || 'uk'}
              placeholderText={'Date' ?? ''}
              onChange={(value): void => field.onChange(value)}
              dateFormat="dd.MM.yyyy"
              calendarStartDay={1}
              required
            />
          )}
          control={control}
        />
      </CCol>
      <CFormLabel
        className="col-sm-2 col-form-label"
        style={{ textAlign: 'end' }}
      >
        {t(`${CashDeskKey.MODAL}.${CashDeskModalKey.TIME}`)}
      </CFormLabel>
      <CCol>
        <Controller
          name="cashDeskTime"
          render={({ field }): ReactElement => (
            <CTimePicker
              disabled={isReadOnly}
              id="cashDeskTime"
              className="js-car-receive-time"
              inputReadOnly
              cleaner={false}
              time={field.value}
              locale="uk-UA"
              seconds={false}
              onShow={onShowTime}
              placeholder={t('time') ?? ''}
              onTimeChange={(timeString, localeTimeString, value): void =>
                field.onChange(value ?? null)
              }
            />
          )}
          control={control}
        />
      </CCol>
    </CRow>
  );
};

export { DatetimeCashDeskRow };
