import { CCardBody, CCardTitle, CFormInput } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { CarServiceProfileFormData } from 'common/types/types';
import { PhoneInputValid } from 'components/common/common';
import React, { FC } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  register: UseFormRegister<CarServiceProfileFormData>,
  setValue:  UseFormSetValue<CarServiceProfileFormData>,
  disabled?: boolean,
};

const ContactCard: FC<Props> = ({ register, disabled, setValue }) => {

  const { t } = useTranslation([AppTranslationKey.CAR_SERVICE, AppTranslationKey.COMMON]);

  const setMainPhoneValue = (value: string): void => {
    setValue('mainContactPhone.contactData', value, { shouldValidate: true });
  };

  const setPhoneValue = (value: string): void => {
    setValue('contactPhone.contactData', value, { shouldValidate: true });
  };

  const setViberValue = (value: string): void => {
    setValue('contactViber.contactData', value, { shouldValidate: true });
  };

  return (
    <div className={styles.cardContainer}>
      <CCardTitle className={styles.cardTitle}>{t('contactsCardTitle')}</CCardTitle>
      <CCardBody>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <PhoneInputValid
                {...register('mainContactPhone.contactData')}
                disabled={disabled ?? false}
                name="mainContactPhone.contactData"
                label={t('mainPhone') ?? 'Main phone'}
                feedbackInvalid={t('maxPhoneLengthError', { ns: AppTranslationKey.COMMON })}
                onChange={setMainPhoneValue}
              />
            </div>
            <div style={{ width: '45%' }}>
              <PhoneInputValid
                {...register('contactPhone.contactData')}
                disabled={disabled ?? false}
                name="contactPhone.contactData"
                label={t('phone') ?? 'Phone'}
                onChange={setPhoneValue}
                feedbackInvalid={t('maxPhoneLengthError', { ns: AppTranslationKey.COMMON })}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 15,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '45%' }}>
              <PhoneInputValid
                {...register('contactViber.contactData')}
                disabled={disabled ?? false}
                name="contactViber.contactData"
                label={t('viber') ?? 'Viber'}
                onChange={setViberValue}
                feedbackInvalid={t('maxPhoneLengthError', { ns: AppTranslationKey.COMMON })}
              />
            </div>
            <div style={{ width: '45%' }}>
              <CFormInput
                {...register('contactTelegram.contactData')}
                disabled={disabled}
                type="text"
                label={t('telegram')}
                name="contactTelegram.contactData"
              />
            </div>
          </div>
          <div style={{ marginTop: 18 }}>
            <CFormInput
              {...register('contactEmail.contactData')}
              disabled={disabled}
              type="text"
              label={t('email')}
              name="contactEmail.contactData"
            />
          </div>
        </div>
      </CCardBody>
    </div>
  );
};

export { ContactCard };
