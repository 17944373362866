import { CCol, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { CarModalFormData, Fuel } from 'common/types/types';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<CarModalFormData>,
  fuel: Fuel[],
  disabled?: boolean,
};

const FuelTypeField: FC<Props> = ({ disabled, register, fuel }) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="fuelType" className="col-sm-3 col-form-label">
        {t('fuelType')}
      </CFormLabel>
      <CCol sm={9}>
        <CFormSelect
          disabled={disabled}
          { ...register('fuelTypeId') }
          id="fuelTypeId"
          name="fuelTypeId"
        >
          <option key={-1} value={-1}>{disabled ? '' : t('selectFuelType')}</option>
          {
            fuel.map((fuel) => (
              <option key={fuel.fuelTypeId} value={fuel.fuelTypeId}>
                {fuel.fuelTypeName}
              </option>
            ))
          }
        </CFormSelect>
      </CCol>

    </CRow>);
};

export { FuelTypeField };
