import { EmployeeFormData } from 'common/types/types';
import { getFormattedDate } from 'helpers/helpers';

const DEFAULT_EMPLOYEE_MODAL_PAYLOAD: EmployeeFormData = {
  firstName: '',
  secondName: '',
  patronymicName: '',
  birthDate: null,
  registrationDate: getFormattedDate(new Date(), 'dd.MM.yyyy'),
  positionId: 1,
  dismissalDate: null,
  statusId: 1,
  salaryTypes: [
    { salaryTypeId: 1, salaryTypeValue: null },
    { salaryTypeId: 2, salaryTypeValue: null },
    { salaryTypeId: 3, salaryTypeValue: null },
    { salaryTypeId: 4, salaryTypeValue: null },
  ],
  employeeInfo: '',
  phone: { value: '' },
  email: { value: '' },
  viber: { value: '' },
  telegram: { value: '' },
};

export { DEFAULT_EMPLOYEE_MODAL_PAYLOAD };
