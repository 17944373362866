import { CCol, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';
import { AppTranslationKey, CashDeskKey, CashDeskModalKey } from 'common/enums/enums';
import { useAppSelector } from 'hooks/hooks';
import { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { CashDeskFormData } from '../type';

type Props = {
  disabled: boolean,
  register: UseFormRegister<CashDeskFormData>,
};

const CalculateMethodCashDeskRow: FC<Props> = ({ register, disabled }) => {

  const { t } = useTranslation(AppTranslationKey.CASH_DESK);
  const { paymentMethods } = useAppSelector(({ dictionary })=> ({
    paymentMethods: dictionary.data?.paymentMethods ?? [],
  }));

  const sortedPaymentMethods =
    paymentMethods.length > 0 ?
      [...paymentMethods].sort((a, b) => a.listPosition - b.listPosition) : [];

  return(
    <CRow className="pt-1">
      <CFormLabel htmlFor="cashDeskPaymentMethodId" className="col-sm-2 col-form-label align-self-center">
        {t(`${CashDeskKey.MODAL}.${CashDeskModalKey.CASH_DESK_CALC_METHOD_LABEL}`)}
      </CFormLabel>
      <CCol sm={10} className="align-self-center">
        <CFormSelect
          disabled={disabled}
          { ...register('cashDeskPaymentMethodId')}
          id="cashDeskPaymentMethodId"
        >
          {
            sortedPaymentMethods.map(({ paymentMethodId, paymentMethodName }) => (
              <option key={paymentMethodId} value={paymentMethodId}>
                {paymentMethodName}
              </option>
            ))
          }
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export { CalculateMethodCashDeskRow };
