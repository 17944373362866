import { SorterValue } from '@coreui/react-pro/dist/components/smart-table/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { CarDto, CarServiceDto, DeleteClientSparePartsRequest, OrderDto, WorkDto } from 'common/types/types';
import { compareDate, compareNumber, compareString } from 'helpers/helpers';

import {
  createCarService,
  getAllCarServiceByUserId,
  getOrdersByCarServiceId,
  getOrdersByFilters,
  updateCarServiceById,
} from './actions';

type State = {
  dataStatus: DataStatus;
  updateDataStatus: DataStatus;
  getOrdersDataStatus: DataStatus;
  userCarServices: CarServiceDto[];
  orders: OrderDto[];
};

const initialState: State = {
  dataStatus: DataStatus.IDLE,
  updateDataStatus: DataStatus.IDLE,
  getOrdersDataStatus: DataStatus.IDLE,
  userCarServices: [],
  orders: [],
};

const carServicesSlice = createSlice({
  name: 'car-services',
  initialState,
  reducers: {
    updateOrderCar: (state, action: PayloadAction<CarDto>) => {
      state.orders = state.orders.map((item) => {
        if (item.car && item.car.carId === action.payload.carId) {
          return { ...item, car: action.payload };
        }

        return item;
      });
    },
    clearCarServices: (state) => {
      state.userCarServices = [];
    },
    clearCarServiceOrders: (state) => {
      state.orders = [];
    },
    updateClientSPLocally: (state, action: PayloadAction<OrderDto>) => {
      const { id, sparePartsFromClient } = action.payload;
      state.orders = state.orders.map((it) => {

        if(it.id === id) {
          it.sparePartsFromClient = sparePartsFromClient;
        }

        return it;
      });
    },
    removeSPFromOrderLocally: (state, action: PayloadAction<DeleteClientSparePartsRequest>) => {
      const { orderId, sparePartIdList } = action.payload;
      state.orders = state.orders.map((it) => {

        if (it.id === orderId) {
          it.sparePartsFromClient =
            it.sparePartsFromClient
              .filter((item) => item.sparePartId && !sparePartIdList.includes(item.sparePartId));
        }

        return it;
      });
    },
    removeWorkFromOrderLocally: (state, action: PayloadAction<{ orderId: number, rowId: number }>) => {
      const { orderId, rowId } = action.payload;
      state.orders = state.orders.map((it) => {

        if (it.id === orderId) {
          it.works = it.works.filter((work) => work.rowId !== rowId);
        }

        return it;
      });
    },
    addWorkToOrderLocally: (state, action: PayloadAction<{ orderId: number, work: WorkDto }>) => {
      const { orderId, work } = action.payload;
      state.orders = state.orders.map((it) => {

        if (it.id === orderId) {
          it.works.push(work);
        }

        return it;
      });
    },
    updateWorkInOrderLocally: (state, action: PayloadAction<WorkDto>) => {
      const { payload } = action;
      state.orders = state.orders.map((it) => {
        if (it.id === payload.requestId) {
          it.works = it.works.map((item) => {
            if (item.rowId === payload.rowId) {
              return payload;
            }

            return item;
          });
        }

        return it;
      });
    },
    ordersSort: (state, action: PayloadAction<SorterValue>) => {
      const { column  } = action.payload;

      switch (column) {
      case 'totalSum':
      case 'sumOrders':
      case 'sumWorksWithoutOrders':
      case 'id': {
        state.orders = state.orders.sort((a,b) => {
          return compareNumber(a[column], b[column], action.payload.state as string);
        },
        );
        break;
      }
      case 'dateCarReceive': {
        state.orders = state.orders.sort((a,b) => {
          return compareDate(new Date(a.dateCarReceive), new Date(b.dateCarReceive), action.payload.state as string);
        });
        break;
      }
      case 'auto': {
        state.orders = state.orders.sort((a,b) => {
          return compareString(
            `${a.car?.carBrandName} ${a.car?.carModelName}`,
            `${b.car?.carBrandName} ${b.car?.carModelName}`,
            action.payload.state as string);
        });
        break;
      }
      case 'statusName': {
        state.orders = state.orders.sort((a,b) => {
          return compareString(
            a.status.name,
            b.status.name,
            action.payload.state as string);
        });
        break;
      }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      //GET ALL CAR-SERVICES BY USER ID
      .addCase(getAllCarServiceByUserId.pending, (state) => {
        state.dataStatus = DataStatus.PENDING;
      })
      .addCase(getAllCarServiceByUserId.rejected, (state) => {
        state.dataStatus = DataStatus.REJECTED;
        state.userCarServices = [];
      })
      .addCase(getAllCarServiceByUserId.fulfilled, (state, { payload }) => {
        state.dataStatus = DataStatus.FULFILLED;
        state.userCarServices = payload;
      })
      //UPDATE CAR-SERVICES BY ID
      .addCase(updateCarServiceById.pending, (state) => {
        state.updateDataStatus = DataStatus.PENDING;
      })
      .addCase(updateCarServiceById.rejected, (state) => {
        state.updateDataStatus = DataStatus.REJECTED;
      })
      .addCase(updateCarServiceById.fulfilled, (state, { payload }) => {
        state.updateDataStatus = DataStatus.FULFILLED;
        state.userCarServices = state.userCarServices.map((item) => {
          if (item.id === payload.id) {
            return { ...item, ...payload };
          }

          return item;
        });
      })
      //CREATE CAR-SERVICE
      .addCase(createCarService.pending, (state) => {
        state.updateDataStatus = DataStatus.PENDING;
      })
      .addCase(createCarService.rejected, (state) => {
        state.updateDataStatus = DataStatus.REJECTED;
      })
      .addCase(createCarService.fulfilled, (state, { payload }) => {
        state.updateDataStatus = DataStatus.FULFILLED;
        state.userCarServices.push(payload);
      })

      //GET ORDERS BY CAR-SERVICE ID
      .addCase(getOrdersByCarServiceId.pending, (state) => {
        state.getOrdersDataStatus = DataStatus.PENDING;
      })
      .addCase(getOrdersByCarServiceId.rejected, (state) => {
        state.getOrdersDataStatus = DataStatus.REJECTED;
        state.orders = [];
      })
      .addCase(getOrdersByCarServiceId.fulfilled, (state, { payload }) => {
        state.getOrdersDataStatus = DataStatus.FULFILLED;
        state.orders = payload;
      })

      //GET ORDERS BY FILTERS
      .addCase(getOrdersByFilters.pending, (state) => {
        state.getOrdersDataStatus = DataStatus.PENDING;
      })
      .addCase(getOrdersByFilters.rejected, (state) => {
        state.getOrdersDataStatus = DataStatus.REJECTED;
        state.orders = [];
      })
      .addCase(getOrdersByFilters.fulfilled, (state, { payload }) => {
        state.getOrdersDataStatus = DataStatus.FULFILLED;
        state.orders = payload;
      });
  },
});

const carServicesReducer = carServicesSlice.reducer;
const {
  addWorkToOrderLocally,
  clearCarServices,
  clearCarServiceOrders,
  removeWorkFromOrderLocally,
  updateWorkInOrderLocally,
  ordersSort,
  updateClientSPLocally,
  removeSPFromOrderLocally,
  updateOrderCar,
} = carServicesSlice.actions;

export {
  addWorkToOrderLocally,
  carServicesReducer,
  clearCarServiceOrders,
  clearCarServices,
  ordersSort,
  removeSPFromOrderLocally,
  removeWorkFromOrderLocally,
  updateClientSPLocally,
  updateOrderCar,
  updateWorkInOrderLocally,
};
