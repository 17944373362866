import { CButton, CSmartTable } from '@coreui/react-pro';
import { SorterValue } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey, LocalStorageKey } from 'common/enums/enums';
import { CashOrdersTableRow } from 'common/types/types';
import { getItemPerPageOptions } from 'helpers/helpers';
import { useAppSelector, useMemo, usePersistentPagination, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getCashOrdersColumns } from './helpers/get-cash-orders-columns.helper';
import { getCashOrdersTableData } from './helpers/get-cash-orders-table-data.helper';

type Props = {
  activeCarServiceId: number,
  onClickId: (id: number) => void,
};

const CashOrdersList: FC<Props> = ({ activeCarServiceId, onClickId }) => {

  const { cashOrders } = useAppSelector(({ cashDesk }) => ({
    cashOrders: cashDesk.cashOrders ?? [],
    cashDeskDataStatus: cashDesk.listCashOrdersUpdateStatus,
  }));
  const cashOrdersTableColumns = useMemo(() => getCashOrdersColumns(), []);
  const cashOrdersTableData = useMemo(() => getCashOrdersTableData(cashOrders), [cashOrders]);
  const { paginationState, handleSizeChange, handlePageChange } = usePersistentPagination(activeCarServiceId);
  const { t } = useTranslation(AppTranslationKey.COMMON);

  const itemsPerPage =
    useMemo( () => getItemPerPageOptions(cashOrdersTableData.length), [ cashOrdersTableData ] );

  const [sorterValue, setSorterValue] =
    useState<SorterValue>(JSON.parse(localStorage.getItem( LocalStorageKey.SORTER_STATE ) ??
      '{"column": "id", "state": "asc"}'),
    );

  const handleSorterChange = (value: SorterValue): void => {
    localStorage.setItem( LocalStorageKey.SORTER_STATE , JSON.stringify(value));
    setSorterValue(value);
  };

  return (
    <CSmartTable
      columns={cashOrdersTableColumns}
      columnSorter={{
        resetable: true,
      }}
      onSorterChange={ handleSorterChange }
      sorterValue={sorterValue}
      items={cashOrdersTableData}
      itemsPerPageSelect={true}
      itemsPerPageOptions={itemsPerPage}
      itemsPerPageLabel={t('itemsPerPageLabel') ?? 'Елементів на сторінці'}
      activePage={ paginationState[activeCarServiceId]?.activePage ?? 1 }
      itemsPerPage={ paginationState[activeCarServiceId]?.perPage ?? 10 }
      onActivePageChange={handlePageChange}
      onItemsPerPageChange={handleSizeChange}
      pagination
      scopedColumns={{
        id: (item: CashOrdersTableRow) =>
          <td>
            <CButton
              color="link"
              size={'sm'}
              className="px-0"
              onClick={():void => onClickId(item.id)}
            >
              { item.id }
            </CButton>
          </td>,
      }}
      tableHeadProps={{
        color: 'light',
      }}
      tableProps={{
        striped: true,
        hover: true,
        align: 'middle',
      }}
    />
  );
};

export {  CashOrdersList };
