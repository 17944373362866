import { CCol, CForm, CFormLabel, CModal, CModalBody, CModalHeader, CModalTitle, CRow } from '@coreui/react-pro';
import {
  AppTranslationKey, ClientSparePartsTranslationKey,
  DangerAlertModalMode,
  DataStatus,
  LocalStorageKey,
  ModalFormType,
  NotificationType,
  WorkOrderModalTranslationKey,
  WorkOrderTranslationKey,
} from 'common/enums/enums';
import { worksToSelectedWorks } from 'common/map/maps';
import {
  AddWorkOrderRequest,
  CarWorkFav,
  ClientSpareParts,
  FiltersStorage,
  UserSpareParts,
  WorkOrderFormData,
} from 'common/types/types';
import { initialFilters, TotalField } from 'components/common/common';
import { ModalFooter } from 'components/modals/components/components';
import { DangerAlertModal, SelectOrderModal, SelectSparePartsModal, SelectWorkModal } from 'components/modals/modals';
import { TextArea } from 'components/textarea/text-area';
import {
  currentDateTimeWithZeroMinutes,
  getFormattedDate,
  getPayloadByOrderFilters,
  onlyNumberString,
} from 'helpers/helpers';
import {
  useAppDispatch,
  useAppSelector,
  useCustomerSparePartsModal,
  useEffect,
  useForm,
  useMemo,
  useState,
} from 'hooks/hooks';
import { isNull, toNumber } from 'lodash';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from 'services/services';
import {
  appActions,
  carWorkActions,
  employeesActions,
  sparePartsActions,
  widgetActions,
  workOrderAction } from 'store/actions';
import { disableReadOnlyModalMode } from 'store/modal/reducer';
import {
  addClientSparePartsToWorkOrder,
  addSparePartsToWorkOrder,
  addWorkToBindOrder,
  changeBindPriceSelectedSparePart,
  changeBindPriceSelectedWork,
  changeBindQtySelectedSparePart,
  changeBindQtySelectedWork,
  clearBindOrder,
  clearWorkOrder,
  removeClientSparePartFromWorkOrder,
  removeSparePartsFromWorkOrder,
  removeWorkFromWorkOrder,
} from 'store/work-order/reducer';

import { carWorkFavToWorkDto } from '../select-work-to-order-modal/map/map';
import { DEFAULT_WORK_ORDER_MODAL_PAYLOAD } from './common';
import {
  WorkOrderClientSparePartsRow,
  WorkOrderDateTimeRow,
  WorkOrderRequestRow,
  WorkOrderSparePartsRow,
  WorkOrderStatusRow,
  WorkRow,
} from './components/components';
import { WorkOrderEmployeesRow } from './components/work-order-employees-row/work-order-employees-row';
import { userSparePartToBindSpareParts, workDtoToCarWorkTableRow } from './map/map';
import styles from './style.module.scss';
import { CarWorkTableRow } from './type/type';

type Props = {
  isOpen: boolean,
  isReadOnly: boolean;
  carServiceId: number,
  onCloseModal: () => void,
};

const ORDER_CANCELED_STATUS_ID = 7;
const WorkOrderModal: FC<Props> = ({ isOpen, onCloseModal, carServiceId, isReadOnly }) => {

  const [isSelectRequestOpen, setIsSelectRequestOpen] = useState<boolean>(false);
  const [isSelectWorkOpen, setIsSelectWorkOpen] = useState<boolean>(false);
  const [isSelectSparePartsOpen, setIsSelectSparePartsOpen] = useState<boolean>(false);
  const [isDangerAlertModalOpen, setIsDangerAlertModalOpen] = useState<boolean>(false);
  const [dataForConfirm, setDataForConfirm] = useState<WorkOrderFormData | null>(null);
  const { t } = useTranslation([AppTranslationKey.WORK_ORDER, AppTranslationKey.CLIENT_SPARE_PARTS]);
  const dispatch = useAppDispatch();
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm<WorkOrderFormData>({
    defaultValues: DEFAULT_WORK_ORDER_MODAL_PAYLOAD,
  });
  const {
    workOrdersStatuses,
    bindOrder,
    workList,
    user,
    bindWork,
    bindSpareParts,
    bindClientSpareParts,
    workOrder,
    modifyWorkOrderDataStatus,
    userSpareParts,
    dataStatusUserSpareParts,
    employess,
  }
    = useAppSelector(({ dictionary, workOrder, auth, carWork, spareParts, employees }) => ({
      workOrdersStatuses: dictionary.data?.orderStatuses || [],
      modifyWorkOrderDataStatus: workOrder.modifyWorkOrderDataStatus,
      workList: carWork.favoritesCarWork,
      bindOrder: workOrder.bindOrder,
      bindWork: workOrder.bindWork,
      bindSpareParts: workOrder.bindSpareParts,
      bindClientSpareParts: workOrder.bindClientSpareParts,
      workOrder: workOrder.workOrder,
      user: auth.currentUser,
      userSpareParts: spareParts.userSpareParts,
      dataStatusUserSpareParts: spareParts.dataStatusGetSpareParts,
      employess: employees.employees.data,
    }));

  const { getCustomerSparePartsModal, toggleCustomerSparePartsModal } =
    useCustomerSparePartsModal( bindClientSpareParts ?? [], bindOrder?.orderId ?? null, true);

  const isUpdating = modifyWorkOrderDataStatus === DataStatus.PENDING;
  const isUserSparePartsLoading = dataStatusUserSpareParts === DataStatus.PENDING;
  const processedBindOrder = { ...bindOrder, work: bindOrder?.work.filter((it) => isNull(it.orderId)) ?? [] };
  const watchComment = watch('comment');
  const REQUIRED_MSG = t(`${WorkOrderTranslationKey.WORK_ORDER_MODAL}.${WorkOrderModalTranslationKey.REQUIRED}`);

  useEffect(() => {
    dataForConfirm && setIsDangerAlertModalOpen(true);
  }, [dataForConfirm]);

  useEffect(() => {

    if (isSelectWorkOpen) {
      dispatch(carWorkActions.getFavoritesCarWork({ carServiceId: carServiceId }));
    }
  }, [carServiceId, dispatch, isSelectWorkOpen]);

  useEffect(() => {
    dispatch(employeesActions.getEmployees({ carServiceId }));
  }, [carServiceId]);

  useEffect(() => {

    if (user && isSelectSparePartsOpen) {
      dispatch(sparePartsActions.getSparePartsByUserId({ userId: user.id }));
    }
  }, [dispatch, isSelectSparePartsOpen, user]);

  useEffect(() => {
    if (workOrder) {
      reset({
        workOrderStatusId: workOrder.orderStatus.id,
        order: t(WorkOrderTranslationKey.SELECTED_ORDER_MSG, {
          orderId: workOrder?.requestId ?? '',
          date: getFormattedDate(workOrder?.dateCarReceive, 'yyyy-MM-dd HH:mm') ?? '',
          carInfo: `${workOrder.car.carBrandName ?? ''} ${workOrder.car.carModelName ?? ''} ${workOrder.car.carRegNum}`,
        })
          ?? 'Сan not be shown',
        workOrderDate: workOrder?.dateStartPlane ? new Date(workOrder.dateStartPlane) : new Date(),
        workOrderTime: workOrder?.dateCarReceive ? new Date(workOrder.dateCarReceive) : new Date(),
        comment: workOrder.comment,
        employeeId: workOrder.employeeId,
      });
    } else {
      reset(DEFAULT_WORK_ORDER_MODAL_PAYLOAD);
    }
  }, [reset, workOrder, bindOrder]);

  useEffect(() => {
    if (processedBindOrder.orderId) {
      reset({
        workOrderStatusId: DEFAULT_WORK_ORDER_MODAL_PAYLOAD.workOrderStatusId,
        order: t(WorkOrderTranslationKey.SELECTED_ORDER_MSG, {
          orderId: processedBindOrder?.orderId ?? '',
          date: processedBindOrder?.dateTime ?? '',
          carInfo: processedBindOrder?.carInfo?.join(' ') ?? '',
        })
          ?? 'Сan not be shown',
        workOrderDate: currentDateTimeWithZeroMinutes(),
        workOrderTime: currentDateTimeWithZeroMinutes(),
        comment: processedBindOrder.comment,
      });
    }
  }, [bindOrder]);

  const handleSelectRequestAction = (): void => {
    setIsSelectRequestOpen(true);
  };

  const handleSelectRequestClose = (): void => {
    setIsSelectRequestOpen(false);
    setValue(
      'order',
      processedBindOrder.orderId ?
        t(WorkOrderTranslationKey.SELECTED_ORDER_MSG, {
          orderId: processedBindOrder?.orderId ?? '',
          date: processedBindOrder?.dateTime ?? '',
          carInfo: processedBindOrder?.carInfo?.join(' ') ?? '',
        })
        : '',
      {
        shouldValidate: true,
      });
  };

  const checkBindOrder = (): boolean => {
    if (processedBindOrder.orderId || workOrder?.orderId) return true;

    dispatch(appActions.notify(
      {
        type: NotificationType.ERROR,
        message: t(`${WorkOrderTranslationKey.WORK_ORDER_MODAL}.${WorkOrderModalTranslationKey.NO_BIND_ORDER_ERROR_MSG}`),
      }));
    setError('order',
      {
        type: 'manual',
        message: REQUIRED_MSG,
      });

    return false;

  };

  const handleSelectWorkOpen = (): void => {
    checkBindOrder() && setIsSelectWorkOpen(true);
  };

  const handleDangerAlertClose = (): void => {
    setIsDangerAlertModalOpen(false);
    setDataForConfirm(null);
  };

  const handleSelectWorkClose = (): void => {
    setIsSelectWorkOpen(false);
  };

  const handleSelectSparePartOpen = (): void => {
    checkBindOrder() && setIsSelectSparePartsOpen(true);
  };

  const handleSelectSparePartClose = (): void => {
    setIsSelectSparePartsOpen(false);
  };

  const handleSelectClientSparePartOpen = (): void => {
    checkBindOrder() && toggleCustomerSparePartsModal(true);
  };

  const selectedWorks = useMemo(
    () => {
      return  bindWork.map( (it) => worksToSelectedWorks(it)) ?? [];
    }, [bindWork]);

  const getCarWorkRowData = useMemo((): CarWorkTableRow[] => {
    return bindWork.map((it) => workDtoToCarWorkTableRow(it, true)) ?? [];
  }, [ bindWork ]);

  const workTotalSum = useMemo(() => bindWork.map((it) => parseFloat(it.priceTotal as string)),
    [bindWork]);

  const sparePartsTotalSum = useMemo(() => bindSpareParts.map((it) => parseFloat(it.priceTotal as string)),
    [bindSpareParts]);

  const TotalWorkOrderSum = (): ReactElement => (
    <TotalField
      label={ t(WorkOrderTranslationKey.TOTAL_WORK_ORDER_SUM) }
      items={ [...workTotalSum ?? [], ...sparePartsTotalSum ?? []] }
    />
  );

  const TotalWorkSum = (): ReactElement => (
    <TotalField
      label={ t(WorkOrderTranslationKey.SUM_WORKS) }
      items={ workTotalSum }
      isSub
    />
  );

  const TotalSparePartsSum = (): ReactElement => (
    <TotalField
      label={ t(WorkOrderTranslationKey.SUM_SPARE_PARTS) }
      items={ sparePartsTotalSum }
      isSub
    />
  );

  const handleSelectWork = (work: CarWorkFav): void => {
    if(selectedWorks.some((it) => it.workId === work.serviceWorkId)) {
      handleRemoveWorkItem(work.serviceWorkId);
    } else {
      dispatch(addWorkToBindOrder(carWorkFavToWorkDto(
        work,{
          workCount: 1,
        }),
      ),
      );
    }
  };

  const handleSelectSpareParts = (spareParts: UserSpareParts): void => {
    if(bindSpareParts.some((it) => it.sparePartName === spareParts.sparePartsName)) {
      spareParts.sparePartsName && handleRemoveSparePartsItem(spareParts.sparePartsId);
    } else {
      dispatch(addSparePartsToWorkOrder(userSparePartToBindSpareParts(spareParts)));
    }
  };

  const dummyFunc = (): void => {
    //do nothing
  };

  const handleRemoveWorkItem = (workId: number): void =>  {
    dispatch(removeWorkFromWorkOrder(workId));
  };

  const handleRemoveClientSparePartItem = (sparePart: ClientSpareParts): void => {
    dispatch(removeClientSparePartFromWorkOrder(sparePart));
  };

  const handleRemoveSparePartsItem = (sparePartId: number): void =>  {
    dispatch(removeSparePartsFromWorkOrder(sparePartId));
  };

  const handleChangeWorkQty = (value: string, workId: number):void => {
    dispatch(changeBindQtySelectedWork({ value: onlyNumberString(value), workId }));
  };

  const handleChangeSparePartsQty = (value: string, sparePartId: number):void => {
    dispatch(changeBindQtySelectedSparePart({ value: onlyNumberString(value), sparePartId }));
  };

  const handleChangeWorkPrice = (value: string, workId: number): void =>  {
    dispatch(changeBindPriceSelectedWork({ value: onlyNumberString(value), workId }));
  };

  const handleChangeSparePartsPrice = (value: string, sparePartId: number): void =>  {
    dispatch(changeBindPriceSelectedSparePart({ value: onlyNumberString(value), sparePartId }));
  };

  const updateWorkOrder = (data: WorkOrderFormData): void => {
    dispatch(workOrderAction.updateWorkOrder({ ...data, carServiceId: carServiceId }))
      .unwrap()
      .then(() => {
        const filtersSettings: FiltersStorage = JSON.parse(
          storage.getItem(LocalStorageKey.WORK_ORDER_FILTERS) ?? '{}',
        );
        const payload = getPayloadByOrderFilters(carServiceId, filtersSettings[carServiceId] || initialFilters);

        dispatch(workOrderAction.getWorkOrdersByFilters(payload));
        dispatch(widgetActions.getOpenWorkOrders({ userId: user?.id ?? 0 }));
        dispatch(appActions.notify(
          {
            type: NotificationType.SUCCESS, message: t([WorkOrderTranslationKey.EDIT_SUCCESS]),
          }),
        );
        handleCloseModal();
      })
      .catch(() => {
        //do nothing
      });
  };

  const addWorkOrder = (data: WorkOrderFormData): void => {
    if (data.workOrderStatusId === ORDER_CANCELED_STATUS_ID) {
      dispatch(appActions.notify(
        {
          type: NotificationType.ERROR, message: t([WorkOrderTranslationKey.CREATE_CANCELED_WORK_ORDER_ERROR_TEXT]),
        }));

      return;
    }
    const dateStr = getFormattedDate(data.workOrderDate || new Date(), 'yyyy-MM-dd');
    const timeStr = getFormattedDate(data.workOrderTime || new Date(), 'HH:mm:ss');

    const payload: AddWorkOrderRequest = {
      carServiceId: carServiceId,
      orderStatusId: data.workOrderStatusId,
      comment: data.comment,
      employeeId: data.employeeId,
      dateStartPlane: `${dateStr}T${timeStr}`,
      userId: user?.id ?? 0,
      requestId: processedBindOrder?.orderId ?? 0,
      carId: processedBindOrder?.carId ?? 0,
      orderWorks: bindWork ?? [],
      orderSpareParts: bindSpareParts.map((it) => ({
        priceOne: it.priceOne as number,
        sparePartId: it.sparePartId,
        rowId: it?.rowId ?? null,
        sparePartsCount: it.count as number,
        priceTotal: it.priceTotal as number,
      })),
      sparePartsFromClient: bindClientSpareParts,
    };
    dispatch(workOrderAction.addWorkOrder(payload))
      .unwrap()
      .then(() => {
        dispatch(widgetActions.getOpenWorkOrders({ userId: user?.id ?? 0 }));
        dispatch(appActions.notify({
          type: NotificationType.SUCCESS, message: t([WorkOrderTranslationKey.ADD_SUCCESS]),
        }));
        handleCloseModal();
      })
      .catch(() => {
        //do nothing
      });
  };

  const handleSaveButton = (data: WorkOrderFormData): void => {
    if (workOrder) {
      updateWorkOrder(data);
    } else {
      addWorkOrder(data);
    }
  };

  const onSubmit = (data: WorkOrderFormData): void => {
    if (toNumber(data.workOrderStatusId) === ORDER_CANCELED_STATUS_ID && workOrder) {
      setDataForConfirm(data);
    } else {
      handleSaveButton(data);
    }
  };

  const onEditButtonClick = (): void => {
    if (workOrder?.orderStatus.id === ORDER_CANCELED_STATUS_ID) {
      dispatch(appActions.notify(
        {
          type: NotificationType.ERROR, message: t([WorkOrderTranslationKey.EDIT_CANCELED_WORK_ORDER_ERROR_TEXT]),
        }));
    }
    else {
      dispatch(disableReadOnlyModalMode(ModalFormType.WORK_ORDER_MODAL));
    }
  };

  const handleCloseModal = (): void => {
    onCloseModal();
    reset();
    dispatch(clearWorkOrder());
    dispatch(clearBindOrder());
  };

  const isCanRemoveSelectedWork = (): boolean => true;

  const isDisabledFavWork = (): boolean => false;

  const handleOnConfirmCancelWorkOrder = (): void => {
    dataForConfirm && handleSaveButton({ ...dataForConfirm });
    handleDangerAlertClose();
  };

  const handleSaveClientSpareParts = (clientSpareParts: ClientSpareParts[]): void => {

    workOrder ?
      dispatch(workOrderAction.updateWorkOrderClientSP( {
        clientSpareParts: clientSpareParts,
        workOrder: workOrder,
      }))
        .unwrap()
        .then(() => dispatch(appActions.notify({
          type: NotificationType.SUCCESS,
          message: t(ClientSparePartsTranslationKey.SUCCESS_ADDED_SP, { ns: AppTranslationKey.CLIENT_SPARE_PARTS }),
        }))) :
      dispatch(addClientSparePartsToWorkOrder(clientSpareParts));
    toggleCustomerSparePartsModal(false);
  };

  return (
    <>
      <CModal
        visible={ isOpen }
        backdrop="static"
        onClose={ handleCloseModal }
        size="xl"
        scrollable
      >
        <CModalHeader>
          <CModalTitle>{t(`${WorkOrderTranslationKey.WORK_ORDER_MODAL}.${WorkOrderModalTranslationKey.TITLE}`)} #{workOrder?.orderId}</CModalTitle>
        </CModalHeader>
        <CForm className="form-scroll" onSubmit={handleSubmit(onSubmit)}>
          <CModalBody className={ styles.modalBody }>
            <WorkOrderStatusRow
              label={t(`${WorkOrderTranslationKey.WORK_ORDER_MODAL}.${WorkOrderModalTranslationKey.STATUS_LABEL}`)}
              disabled={isReadOnly}
              workOrdersStatuses={ workOrdersStatuses }
              register={register}
            />
            <WorkOrderRequestRow
              label={t(`${WorkOrderTranslationKey.WORK_ORDER_MODAL}.${WorkOrderModalTranslationKey.ORDER}`)}
              requiredMsg={REQUIRED_MSG}
              selectBtnCaption={t(`${WorkOrderTranslationKey.WORK_ORDER_MODAL}.${WorkOrderModalTranslationKey.SELECT_BTN_CAPTION}`)}
              disabled={workOrder !== null}
              register={register}
              errors={errors}
              onAction={handleSelectRequestAction}
            />
            <WorkOrderDateTimeRow
              isReadOnly={isReadOnly}
              control={control}
            />
            <WorkRow
              isDisable={isReadOnly}
              carWork={getCarWorkRowData}
              onSelectClick={handleSelectWorkOpen}
              onRemoveWorkItem={handleRemoveWorkItem}
              totalLabel={ <TotalWorkSum /> }
            />
            <WorkOrderSparePartsRow
              isDisable={isReadOnly}
              spareParts={bindSpareParts}
              onRemoveSparePartItem={handleRemoveSparePartsItem}
              onSelectClick={handleSelectSparePartOpen}
              totalLabel={ <TotalSparePartsSum /> }
            />
            <WorkOrderClientSparePartsRow
              isDisable={isReadOnly}
              onSelectClick={handleSelectClientSparePartOpen}
              clientSpareParts={bindClientSpareParts}
              onRemoveClientSparePartItem={handleRemoveClientSparePartItem}
            />
            <WorkOrderEmployeesRow
              label={ t(`${WorkOrderTranslationKey.WORK_ORDER_MODAL}.${WorkOrderModalTranslationKey.EMPLOYEE}`) }
              requiredMsg={REQUIRED_MSG}
              isDisable={isReadOnly}
              register={register}
              errors={errors}
              employees={ employess }
            />
            <CRow className="mb-3">
              <CFormLabel htmlFor="comment" className="col-sm-2 col-form-label">
                {t(WorkOrderTranslationKey.COMMENT)}
              </CFormLabel>
              <CCol sm={10}>
                <TextArea<WorkOrderFormData>
                  id="comment"
                  register={ register }
                  limitSymbols={ 1024 }
                  rows={3}
                  nameFiled="comment"
                  value={ watchComment }
                  disabled={ isReadOnly }
                />
              </CCol>
            </CRow>
          </CModalBody>
          <ModalFooter
            isUpdateData={isUpdating}
            isReadOnly={isReadOnly}
            onClose={ handleCloseModal }
            onEditButtonClick={onEditButtonClick}
            desc={ <TotalWorkOrderSum /> }
          />
        </CForm>
      </CModal>
      <SelectOrderModal
        isOpen={isSelectRequestOpen}
        onCloseModal={handleSelectRequestClose}
        carServiceId={carServiceId}
      />
      <SelectWorkModal
        isOpen={isSelectWorkOpen}
        onClose={handleSelectWorkClose}
        onSelectWork={handleSelectWork}
        onEditSelectedWork={dummyFunc}
        onRemoveSelectedWork={handleRemoveWorkItem}
        onChangeQty={handleChangeWorkQty}
        onChangePrice={handleChangeWorkPrice}
        selectedWork={selectedWorks}
        workList={workList}
        isCanRemoveSelectedWork={isCanRemoveSelectedWork}
        isDisabledFavWork={isDisabledFavWork}
      />
      <SelectSparePartsModal
        isOpen={isSelectSparePartsOpen}
        onEditSelectedWork={dummyFunc}
        onRemoveSelectedSpareParts={handleRemoveSparePartsItem}
        onChangeQty={handleChangeSparePartsQty}
        onChangePrice={handleChangeSparePartsPrice}
        onClose={handleSelectSparePartClose}
        userSpareParts={userSpareParts}
        onSelectSpareParts={handleSelectSpareParts}
        selectedSpareParts={bindSpareParts}
        isLoading={isUserSparePartsLoading}
      />
      <DangerAlertModal
        mode={ DangerAlertModalMode.CANCEL_WORK_ORDER }
        isVisible={isDangerAlertModalOpen}
        onClose={handleDangerAlertClose}
        onConfirm={handleOnConfirmCancelWorkOrder}/>
      { getCustomerSparePartsModal({ onSaveClientSpareParts: handleSaveClientSpareParts }) }
    </>
  );
};

export { WorkOrderModal };
